(function () {

    // Strict
    'use strict';

    // Controller
    angular.module('app').controller('toolbar.search.controller', ['$rootScope', '$scope', '$location', '$localStorage', '$element', '$timeout', '$filter', 'toastr', 'log.factory', 'geocode.factory', 'map.factory', function ($rootScope, $scope, $location, $localStorage, $element, $timeout, $filter, toastr, _log, _geocode, _map) {

        // Model
        $scope.address = "";
        
        // Search
        $scope.search = function () {
            // Show loading
            $rootScope.$broadcast('loading:show');

            // Address
            _geocode.search($scope.address).then(function (address) {
                // Close loading
                $rootScope.$broadcast('loading:hide');
                
                // Nothing found
                if (_.isNull(address))
                {
                    // Show modal
                    toastr.error($filter('translate')('labels.addressnotfound'));
                    return;
                }                    

                // Add location marker
                _map.marker.add(ol.proj.fromLonLat([address.geometry.location.lng, address.geometry.location.lat]));

                // Close
                $scope.close();

            }, function (error) {
                // Write to log
                _log.error("toolbar.search.controller", "_address.search", error);
                // Close loading
                $rootScope.$broadcast('loading:hide');
            });
        }

        // Close
        $scope.close = function () {
            // Clear address
            $scope.address = "";
            // Collapse
            $scope.page.search.collapse = false
            // NotFound
            $scope.notfound = false;
        }

        // Watch open
        $scope.$watch(function () { return $scope.page.search.collapse; }, function (newValue, oldValue) {
            if ($scope.page.search.collapse) {
                $timeout(function () {
                    _.first($element.find('#search')).focus();
                });
            }
        });

        // Watch toolbar collapse
        $scope.$watch(function () { return $scope.$parent.page.toolbar.collapse; }, function (newValue, oldValue) {
            if (!$scope.$parent.page.toolbar.collapse) {
                $scope.close();
            }
        });

    }]);

}());