(function () {

    // Strict
    'use strict';

    // Factory
    angular.module('app').factory('geocode.factory', ['$rootScope', '$q', '$http', function ($rootScope, $q, $http) {
        return {
            search: function (address) {

                // Defer
                var deferred = $q.defer();

                // geocode
                $http.get("//maps.google.com/maps/api/geocode/json?address=" + encodeURIComponent(address) + "&sensor=false").then(function (result) {
                    // Default nothing found
                    var location = null;
                    // First result
                    if (result.data.results.length > 0)
                        location = _.first(result.data.results);
                    // Resolve
                    deferred.resolve(location);
                }, function (error) {                    
                    // Error
                    deferred.reject(error.data.message);
                });

                // Return promise
                return deferred.promise;
            }
        };
    }]);

}());
