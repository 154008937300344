(function () {
    'use strict';
    
    angular.module('app').directive('stopwatch', ['$interval', function ($interval) {
        return {
            require: 'ngModel',
            template: '<button type="button" class="btn btn-sm btn-default btn-square" ng-disabled="active" style="margin-right: 2px;" ng-class="{ \'disabled\': active }" ng-click="start();"><span class="fa fa-play"></span></button><button class="btn btn-sm btn-default btn-square" style="margin-right: 2px;" ng-disabled="!active" ng-class="{ \'disabled\': !active }" ng-click="stop();"><span class="fa fa-stop"></span></button>{{ ticks | secondsToDateTime | date:\'HH:mm:ss\' }}',
            scope: {
                sw: '=',
                form: '=',
            },
            link: function ($scope, element, attrs, ngModel) {

                $scope.active = false;
                $scope.ticks = 0;
                $scope.interval = null;

                $scope.start = function () {
                    $scope.active = true;
                    $scope.form.$setDirty();
                    $scope.interval = $interval(function () {
                        $scope.ticks += 1;
                    }, 1000);
                }

                $scope.stop = function () {
                    $scope.active = false;
                    $interval.cancel($scope.interval);
                    $scope.internval = null;

                    var value = ngModel.$viewValue;
                    if (_.isUndefined(value) || _.isNull(value)) {
                        value = 0;
                    }
                    value += $scope.ticks / 3600
                    ngModel.$setViewValue(value);
                    $scope.ticks = 0;
                }

                $scope.$watch('sw', function (value) {
                    /*Checking if the given value is not undefined*/
                    if (value) {
                        $scope.Obj = value;
                        /*Injecting the Method*/
                        $scope.Obj.stopStopWatch = function () {
                            $scope.stop();
                        }
                    }
                });


            }
        }
    }]);

})();