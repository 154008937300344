(function () {

    'use strict';

    angular.module('app').directive('downloadFile', ['$rootScope', '$localStorage', 'file.service', function ($rootScope, $localStorage, _file) {

        return {
            restrict: 'EA',
            replace: true,
            scope: {
                file: '=downloadFile',
            },
            link: function (scope, element, attr, ctrl) {
              
                // Href
                attr.$set('href', $rootScope.settings.webserviceUrl + 'GetFile?handle=' + scope.file.Handle + '&access_token=' + $localStorage.token.access_token + '&download=' + ($rootScope.os != 'ios'));

                // Target
                attr.$set('target', ($rootScope.os == 'ios') ? '_blank' : '_self');     

                // Add class to child element (span)
                angular.element(element.children()[0]).addClass(_file.icon(scope.file.Extension));
            }
        }
    }]);

})();