(function () {

    // Strict
    'use strict';

    // Controller
    angular.module('app').controller('map.controller', ['$rootScope', '$scope', '$window', '$localStorage', '$filter', '$q', '$uibModal', '$compile', 'toastr', 'map.factory', 'api.factory', 'geometry.factory', 'log.factory', 'db.factory', 'modal.service', 'annotation.service','gps.service', function ($rootScope, $scope, $window, $localStorage, $filter, $q, $uibModal, $compile, toastr, _map, _api, _geometry, _log, _db, _modal, _annotation, _gps) {

        // Variabeles
        var request = null;
        var abortDialog = null;

        // Allow select feature
        $scope.evtSelectFeature = true;

        // Initialize map (ol3)
        _map.init();

        // Initialize GPS
        _gps.connect();

        // Capture map click
        _map.map.on('singleclick', function (evt) {

            // Only if new task is null
            if ($rootScope.mapMode.Value != "drawmode" && $scope.evtSelectFeature) {

                // Get feature
                var feature = _map.map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
                    return feature;
                });

                // Highlight tasks
                $rootScope.$broadcast('application:tasks:select', feature);

                // TrenchContent
                if ($rootScope.applicationOnline && !_.isUndefined($localStorage.map.trenchContentLayer) && !_.isNull($localStorage.map.trenchContentLayer)) {
                    _map.map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {

                        // On which layer is the feature drawn
                        var layer = feature.get('layer');

                        // TrechContentLayer
                        if (layer != null && layer != undefined && layer.Name == $localStorage.map.trenchContentLayer) {

                            // Trench Content
                            $scope.feature = feature;
                            $scope.evt = evt;
                            var el = $compile('<trench-content feature="feature" evt="evt" />')($scope);
                            angular.element($("#map-canvas")).append(el);
                        }

                    });
                }                
            }
        });

        // Start draw event
        $rootScope.$on('application:draw:start', function ($event) {
            $scope.evtSelectFeature = false;            
        });

        // Stop draw event
        $rootScope.$on('application:draw:stop', function ($event) {
            $scope.evtSelectFeature = true;
        });

        // Load geometry from API
        $scope.load = function () {

            var deferred = $q.defer();

            // Remove popup
            $rootScope.$broadcast('application:popup:remove');

            // If online
            if ($rootScope.applicationOnline) {
                _geometry.dirty().then(function (geometries) {
                    // No dirty geometry
                    if (geometries.length == 0) {

                        // Show loading
                        $rootScope.$broadcast('loading:show');

                        // Abort pending request
                        abortDialog = _modal.alert({ text: $filter('translate')('labels.loadingdatapleasewait'), ok: $filter('translate')('labels.abort') });
                        abortDialog.result.then(function () {
                            // Abort request
                            if (request)
                                request.abort();
                            request = null;
                            abortDialog = null;
                        });

                        // Extent
                        var extent = _map.tools.extent();
                       
                        // CdoTypes
                        var cdoTypes = _.filter($localStorage.cdotypes, { 'Visible': true });
                        
                        // Clear geometries
                        _db.geometries.clear();

                        // Get Geometries
                        (request = _api.getGeometries(extent, cdoTypes)).then(function (result) {

                            // Remove abort dialog
                            abortDialog.dismiss('cancel');
                            abortDialog = null;

                            // Dialog with processing message
                            var dialog = _modal.alert({ text: $filter('translate')('labels.processdatapleasewait') });

                            // Add geometry to db
                            _db.geometries.add(result.data).then(function () {
                                // Load from db
                                $scope.reload().finally(function () {
                                    // Hide loading
                                    $rootScope.$broadcast('loading:hide');
                                    // Resolve
                                    deferred.resolve();
                                }).finally(function () {
                                    // Hide loading
                                    $rootScope.$broadcast('loading:hide');
                                    // Remove abort dialog
                                    dialog.dismiss('close');
                                    dialog = null;
                                });
                            });
                        }, function (error) {
                            // Hide loading
                            $rootScope.$broadcast('loading:hide');

                            // Remove abort dialog
                            if (abortDialog != null) {
                                abortDialog.dismiss('cancel');
                                abortDialog = null;
                            }

                            // Write to log
                            _log.error("map.controller", "getGeometries", error);
                            // Display error
                            var message = $filter('error')(error);
                            if (!_.isEmpty(message))
                                toastr.error(message, { allowHtml: true });
                        });
                    } else {
                        // Show confirm
                        _modal.alert({ text: $filter('translate')('labels.syncfoundgeometrydirty'), ok: $filter('translate')('labels.ok') }).result.then(function () {
                            // Show loading
                            $rootScope.$broadcast('loading:show');
                            // Moeten we hier misschien de taken uit de indexedDB halen?
                            $scope.reload().finally(function () {
                                // Hide loading
                                $rootScope.$broadcast('loading:hide');
                                // Resolve
                                deferred.resolve();
                            });
                        });
                    }
                });
            } else {
                _modal.alert({ text: $filter('translate')('messages.applicationofflinecache'), ok: $filter('translate')('labels.ok') }).result.then(function () {
                    // Show loading
                    $rootScope.$broadcast('loading:show');
                    // Load from cache
                    $scope.reload().finally(function () {
                        // Hide loading
                        $rootScope.$broadcast('loading:hide');
                        // Resolve
                        deferred.resolve();
                    });
                });
            }

            return deferred.promise;
        };

        // Reload geometry
        $scope.reload = function () {

            // Defer
            var defer = $q.defer();

            // Clear layers
            _map.layers.clear();

            // Add each feature to map
            _geometry.fromIndexedDB().then(function (features) {
              
                // GroupBy CdoId
                _.each(_.groupBy(features, function (feature) {
                    return feature.get('CdoId');
                }), function (values, key) {
                    _map.layers.vector(key).getSource().addFeatures(values);
                });

                // Resolve
                defer.resolve();
            }, function (error) {
                defer.reject(error);
            });

            // Return promise
            return defer.promise;
        };

        // Reload
        $scope.reload();

        // Event Reload Geometry from localstorage
        $scope.mapReloadListener = $rootScope.$on('application:map:reload', $scope.reload);

        // Event Load Geometry from API
        $scope.mapLoadListener = $rootScope.$on('application:map:load', $scope.load);

        // Event toggle WMS visibility
        $scope.wmsVisibilityListener = $rootScope.$on('application:map:wms:visible', function () {
            _map.wms.toggleVisibility();
        });

        // If window resize
        angular.element($window).bind('resize', function () {
            // Resize
            _map.tools.updateSize();
        });

        // On destroy panel
        $scope.$on('$destroy', function () {
            // Destroy events
            $scope.mapReloadListener();
            $scope.mapLoadListener();
            $scope.wmsVisibilityListener();
        });
    }]);
}());