(function () {

    // Strict
    'use strict';

    // Controller
    angular.module('app').controller('home.controller', ['$rootScope', '$scope', '$document', '$timeout', '$location', '$anchorScroll', 'map.factory', function ($rootScope, $scope, $document, $timeout, $location, $anchorScroll, _map) {

        // Page settings
        $scope.page = {
            fullscreen: {
                map: false,
                panel: false
            }
        };

        // Panel
        $rootScope.panels = [];

        // Watch panels toggle fullscreen
        $scope.$watch(function () { return $rootScope.panels; }, function (newValue, oldValue) {
            $scope.page.fullscreen.panel = $rootScope.panels.length > 0;            
            _map.tools.updateSize();
        }, true);

        // Close main panel
        $scope.close = function () {
            // Make fullscreen
            $scope.page.fullscreen.panel = false;
            $scope.page.fullscreen.map = !$scope.page.fullscreen.map;
            _map.tools.updateSize();
        };

        // Resize
        $scope.resize = function (fullscreen) {
            // Make fullscreen
            $scope.page.fullscreen.panel = fullscreen;
            $scope.page.fullscreen.map = false;
            _map.tools.updateSize().then(function () {
                $rootScope.$broadcast('application:panel:resize');
            });
        };

        // Open task panel
        $scope.tasks = function () {
            $scope.page.fullscreen.panel = $rootScope.panels.length > 0;
            $scope.page.fullscreen.map = false;
            _map.tools.updateSize().then(function () {
                $rootScope.$broadcast('application:sidebar:tasks');
            });
        };

        // Open map panel
        $scope.map = function () {
            $scope.page.fullscreen.panel = false;
            $scope.page.fullscreen.map = true;
            _map.tools.updateSize().then(function () {
                $rootScope.$broadcast('application:sidebar:map');
            });
        };

        // Fullscreen map
        $rootScope.$on('application:map:fullscreen', $scope.map);

        // Fullscreen tasks
        $rootScope.$on('application:tasks:fullscreen', $scope.tasks);

        // If needed show map when start drawing
        $rootScope.$on('application:draw:start', function () {
            if (!$scope.page.fullscreen.map && !$rootScope.mapAlwaysOnTop)
                $scope.map();
        });

        // If needed show tasks when stop drawing
        $rootScope.$on('application:draw:stop', function () {
            if (!$scope.page.fullscreen.panel && !$rootScope.mapAlwaysOnTop)
                $scope.tasks();
        });
    }]);

}());