(function () {
    'use strict';

    angular.module('app').factory('modal.service', ['$confirm', '$uibModal', function ($confirm, $uibModal) {
        return {
            alert: function (options) {

                if (_.isUndefined(options.title))
                    options.title = null;
                if (_.isUndefined(options.ok))
                    options.ok = null;

                return $uibModal.open({
                    backdrop: 'static',
                    template: '<div class="modal-header" ng-if="options.title != null"><h3 class="modal-title">{{ options.title }}</h3></div><div class="modal-body" ng-bind-html="options.text"></div><div class="modal-footer modal-footer-center" ng-if="options.ok != null"><button class="btn btn-primary" ng-click="close();">{{ options.ok }}</button></div>',
                    controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                        $scope.options = options;
                        $scope.close = function (result) {
                            $uibModalInstance.close(result);
                        };
                    }],
                    size: 'sm',
                });
            }
        }
    }]);

})();