(function () {
    'use strict';

    angular.module('app').factory('gps.service', ['$rootScope', '$q', '$timeout', '$confirm', '$filter', 'map.factory', 'gpsrover.factory', function ($rootScope, $q, $timeout, $confirm, $filter, _map, _gpsrover) {

        // Variables
        var position = null, accuracy = null, noFixDialog = null;

        // Instantiate ol.Geolocation
        function geoloc() {

            var geolocation = new ol.Geolocation({
                projection: _map.map.getView().getProjection(),
                trackingOptions: { // https://www.w3.org/TR/geolocation-API/#position_options_interface
                    enableHighAccuracy: true
                }
            });
            geolocation.setTracking(true);

            // Watch position
            geolocation.on('change:position', function () {
                $rootScope.$apply(function () {
                    position = new ol.geom.Point(geolocation.getPosition());
                });
            });

            // Watch accuracy
            geolocation.on('change:accuracyGeometry', function () {
                $rootScope.$apply(function () {
                    accuracy = geolocation.getAccuracyGeometry();
                });
            });
        }

        // Watch position
        $rootScope.$watch(function () { return position; }, function (newValue, oldValue) {
            $rootScope.$broadcast("application:gps:position", position);
        });

        // Watch accuracy
        $rootScope.$watch(function () { return accuracy; }, function (newValue, oldValue) {
            $rootScope.$broadcast("application:gps:accuracy", accuracy);
        });

        return {
            connect: function () {
                var deferred = $q.defer();
                // First connecto GPS rover, then Geolocation
                if ($rootScope.settings.useGpsRover) {
                    _gpsrover.connect().then(function () {

                        // Na 15 seconden melding dat er geen fix gevonden is.
                        noFixDialog = $timeout(function () {
                            $confirm({
                                text: $filter('translate')('messages.gpsrovernofix'),
                                title: $filter('translate')('messages.gpsrovernofixtitle'),
                                ok: $filter('translate')('labels.ok'),
                                cancel: $filter('translate')('labels.cancel')
                            }, { backdrop: 'static' }).then(function () {
                                _gpsrover.disconnect();
                                geoloc();
                                deferred.reject();
                            });
                        }, 15000);

                        $rootScope.$on("application:gpsrover:gpsFixReceived", function (event, args) {
                            // Cancel timeout
                            if (!_.isUndefined(noFixDialog))
                                $timeout.cancel(noFixDialog);
                            // Apply position and accuracy
                            $rootScope.$apply(function () {
                                position = args.position;
                                accuracy = args.accuracy;
                            });
                        });
                        $rootScope.$on("application:gpsrover:disconnected", function (event) {
                            geoloc();
                        });
                        deferred.resolve();
                    }, function () {
                        // GPS Rover failed, connect to geolocation
                        geoloc();
                        // Reject
                        deferred.reject();
                    });
                } else {
                    // Use geolocation
                    geoloc();
                }
                return deferred.promise;
            },
            disconnect: function () {
                if (!_.isUndefined(noFixDialog))
                    $timeout.cancel(noFixDialog);
                _gpsrover.disconnect();
            },
            position: function () {
                if (!_.isNull(position))
                    return position.getCoordinates();
                return null;
            }
        }

    }]);
})();