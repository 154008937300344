(function () {

    // Strict
    'use strict';

    // Error filter
    angular.module('app').filter('error', [function () {
        return function (error) {

            // Access denied, ignore error
            if (error.status == 401 || error.status == 403)
                return;

            // Message
            var msg = "";
            if (!_.isNull(error) && !_.isNull(error.data)) {
                msg = error.data.Message;
                if (!_.isUndefined(error.data.ExceptionMessage))
                    msg += "<br/>" + error.data.ExceptionMessage;
                if (!_.isNull(error.data.InnerException) && !_.isUndefined(error.data.InnerException))
                    msg += "<br/>" + error.data.InnerException.ExceptionMessage;
            }
            return msg;
        }
    }]);
    
})();