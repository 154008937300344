(function () {

    // Strict
    'use strict';

    // Controller
    angular.module('app').controller('toolbar.report.controller', ['$scope', '$filter', '$localStorage', 'map.factory', function ($scope, $filter, $localStorage, _map) {

    }]);

}());