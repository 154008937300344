(function () {

    // Strict
    'use strict';

    // Controller
    angular.module('app').controller('toolbar.controller', ['$rootScope', '$scope', '$filter', '$sessionStorage', '$localStorage', '$timeout', 'toastr', 'map.factory', 'api.factory', 'log.factory', 'db.factory', 'gps.service', function ($rootScope, $scope, $filter, $sessionStorage, $localStorage, $timeout, toastr, _map, _api, _log, _db, _gps) {

        // Settings
        $scope.page = {
            toolbar: {
                collapse: false
            },
            search: {
                collapse: false
            },
            disabled: false
        }
        
        // Refresh
        $scope.refresh = function () {
            // Load geometry
            $rootScope.$broadcast('application:map:load');
        }

        // Start draw event
        $rootScope.$on('application:draw:start', function ($event) {
            
            var cdoId = $rootScope.selectedTask.Activity.CdoId;
                        
            // Draw layer
            var layer = _.find($localStorage.map.layers, { 'CdoId': cdoId });

            // Only if coordinates is null
            if (!_.isUndefined(layer)) {
                // Disable toolbar
                $scope.page.disabled = true;    
                // Collapse toolbar
                $scope.page.toolbar.collapse = true;
                // Select mapmode 'drawmode'
                $scope.selectMapMode(null, _.find($scope.mapModes, { Value: 'drawmode' }));
                // Select layer by CdoId
                $timeout(function () {
                    $rootScope.$broadcast('application:draw:start:selectlayer', { 'layer': layer });
                }, 250);
            }
        });

        // Zoom
        $scope.zoom = function (options) {
            // Map View
            var view = _map.map.getView();

            // Zoom
            //view.setZoom(view.getZoom() + options.direction);
            view.animate({
                resolution: view.getResolution(),
                duration: 500,
                zoom: view.getZoom() + options.direction,
                easing: ol.easing.easeIn
            });
        }

        // Home
        $scope.buttons = { home: false };
        $scope.home = function () {
            _map.map.getView().setCenter(_gps.position()); 
        }

        $rootScope.$on("application:gps:position", function () {
            $scope.buttons.home = !_.isUndefined(_gps.position()) && !_.isNull(_gps.position());            
        });

        // End Events

        // Mapmodus
        $scope.mapModes = [
                { Value: 'mapmode', Icon: 'fa-map-o', Enabled: true },
                { Value: 'drawmode', Icon: 'fa-pencil-square-o', Enabled: $scope.settings.subMenuSettings.drawModeEnabled },
        ];

        // MapMode
        $rootScope.mapMode = _.first($scope.mapModes);

        // Select Mapmode
        $scope.selectMapMode = function ($event, mapMode) {
            if (!mapMode.Enabled) {
                $event.stopPropagation();
                $event.preventDefault();
                return false;
            }
            // Close search
            $scope.page.search.collapse = false;
            // Change mapMode
            $rootScope.mapMode = mapMode;
        }

        // Watch online/offline
        $scope.$watch(function () { return $rootScope.applicationOnline; }, function (newValue, oldValue) {
            if (!$rootScope.applicationOnline && $scope.page.search.collapse)
                $scope.page.search.collapse = false;
        });
    }]);

}());