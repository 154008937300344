// Calculate distance between pixels
function distanceBetweenPixels(pixel1, pixel2) {
    return Math.sqrt(
      Math.pow(pixel2[0] - pixel1[0], 2) +
      Math.pow(pixel2[1] - pixel1[1], 2)
  );
}

function extension(name) {
    return '.'+name.split('.')[1];
}