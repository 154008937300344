(function () {

    // Strict
    'use strict';

    angular.module('app').directive('trenchContent', ['$rootScope', '$localStorage', '$timeout', 'map.factory', 'api.factory', function ($rootScope, $localStorage, $timeout, _map, _api) {
        return {
            restrict: 'E',
            //replace: true,
            //transclude: true,
            templateUrl: './app/trenchcontent/trench-content.directive.html',
            scope: {
                feature: '=feature',
                evt: '=evt'
            },
            controller: ['$rootScope', '$scope', '$element', function ($rootScope, $scope, $element) {

                // Loading
                $scope.loading = true;

                // Variables
                $scope.content = [];
                
                // Init
                $scope.load = function () {

                    // Close event
                    $rootScope.$broadcast('application:dialog:trenchcontent:close');

                    // Overlay
                    $scope.overlay = new ol.Overlay({
                        element: $element[0],
                        positioning: 'bottom-center',
                        position: $scope.evt.coordinate,
                        stopEvent: true,
                        autoPan: true,
                        insertFirst: false,
                        autoPanAnimation: {
                            duration: 250
                        }
                    });
                    _map.map.addOverlay($scope.overlay);
                    
                    // Info Column Names
                    _api.getInfoColumnNames($scope.feature.get('CdoId')).then(function (columns) {
                        columns = _.filter(columns, function (column) {
                            return column.startsWith('TcLabel_');
                        });
                        // If there are no columns continue
                        if (columns.length > 0) {
                            _api.getObjectInfoValues($scope.feature.get('Handle'), columns).then(function (data) {
                                // Data
                                $scope.content = _.compact(data);
                                // No more loading
                                $scope.loading = false;
                            });
                        } else {
                            // No more loading
                            $scope.loading = false;
                        }
                    });
                }
                
                // Close
                $scope.close = function () {
                    // Hide overlay
                    if(!_.isUndefined($scope.overlay))
                        $scope.overlay.setPosition(undefined);
                    // Reset variables
                    $scope.data = [];                    
                }

                // On close
                $rootScope.$on('application:dialog:trenchcontent:close', $scope.close);

                // Init 
                $timeout($scope.load, 0);                                               
            }],
            compile: function ($scope, element, attrs) { },
        }
    }]);
}());