(function () {

    // Strict
    'use strict';

    // Factory
    angular.module('app').factory('gpsrover.factory', ['$rootScope', '$q', '$filter', 'log.factory', 'toastr', 'map.factory', function ($rootScope, $q, $filter, _log, toastr, _map) {

        // SignalR hub proxy
        var proxy = null;

        // Default disconnected
        $rootScope.gpsHubState = $.signalR.connectionState.disconnected;

        // SingalR hub
        var connection = $.hubConnection('https://localhost:44398');

        // Proxy
        proxy = connection.createHubProxy('gpsHub');

        // Events
        proxy.on('positionReceived', function (event) {
            $rootScope.$broadcast('application:gpsrover:positionReceived', HandleEvent(event));
        });
        proxy.on('sateliteStatusReceived', function (event) {
            // Not Implemented
        });
        proxy.on('gpsFixReceived', function (event) {         
            $rootScope.$broadcast('application:gpsrover:gpsFixReceived', HandleEvent(event));
        });
        proxy.on('satelitesInViewReceived', function (event) {
            // Not Implemented
        });

        // Watch the state change and send the state to the rootScope
        connection.stateChanged(function (e) {
            $rootScope.gpsHubState = e.newState;    
        });
        
        function HandleEvent(event) {

            var position = new ol.geom.Point(ol.proj.transform([event.Location.Longitude, event.Location.Latitude], 'EPSG:4326', _map.map.getView().getProjection()));

            // Source: https://en.wikipedia.org/wiki/Dilution_of_precision_(navigation)
            // < 1 Ideal
            // 1-2 Excellent
            // 2-5 Good
            // 5-10 Moderate
            // 10-20 Fair
            // > 20 Poor
            var radius = event.HorizontalDilution * 5;
            var view = _map.map.getView();
            var projection = view.getProjection();
            var resolutionAtEquator = view.getResolution();
            var center = position.getCoordinates();
            var pointResolution = projection.getPointResolution(resolutionAtEquator, center);
            var resolutionFactor = resolutionAtEquator / pointResolution;
            var radius = (radius / ol.proj.METERS_PER_UNIT.m) * resolutionFactor;
            var circle = new ol.geom.Circle(center, radius);

            return {
                position: position,
                accuracy: circle
            }
        }

        var rover = {
            connect: function () {
                var deferred = $q.defer();                
                connection.start().done(function () {
                    console.log('GPS Bluetooth Rover connected');
                    deferred.resolve();
                }).fail(function (error) {
                    _log.error("gpsrover", "rover.connect", $filter('translate')('messages.gpsroverconnectfail'));                    
                    deferred.reject(error);
                });
                return deferred.promise;
            },
            disconnect: function () {
                if (connection != null && $rootScope.gpsHubState == $.signalR.connectionState.connected) {                    
                    connection.stop();
                    console.log('GPS Bluetooth Rover disconnected');                                    
                }                
                proxy = null;    
                $rootScope.gpsHubState = $.signalR.connectionState.disconnected;
                $rootScope.$broadcast('application:gpsrover:disconnected');
            }
        };

        // Error
        connection.error(function (error) {
            // Write to log
            _log.error("gpsrover", "rover.connection.error", error.toString());
            // If connected display a message that the connection is lost.
            if ($rootScope.gpsHubState == $.signalR.connectionState.connected) {
                toastr.error($filter('translate')('messages.gpsroverconnectionlost'));                 
            }
            // Disconnect
            rover.disconnect();
        });
        
        return rover;
    }]);      

}());