(function () {

    'use strict';

    angular.module('app').directive("double", function ($rootScope) {
        return {
            restrict: "A", // Only usable as an attribute of another HTML element
            require: "?ngModel",
            link: function (scope, element, attr, ngModel) {
                // Run when the model is first rendered and when the model is changed from code
                ngModel.$render = function () {
                    if (ngModel.$modelValue != null && ngModel.$modelValue != '') {
                        element.val(ngModel.$modelValue.toString().replace(".", $rootScope.settings.decimalSeperator));
                    }
                }
            }
        }
    });

})();