(function () {

    // Strict
    'use strict';

    angular.module('app').filter('secondsToDateTime', [function () {
        return function (seconds) {
            return new Date(1970, 0, 1).setSeconds(seconds);
        };
    }]);

    angular.module('app').filter('hoursToDateTime', [function () {
        return function (hours) {
            var dt = new Date(1970, 0, 1);
            if (!_.isUndefined(hours)) {
                return dt.setSeconds(hours * 3600);
            } else {
                return dt;
            }
        };
    }]);

})();