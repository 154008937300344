(function () {

    'use strict';

    angular.module('app').directive("trackMe", ['$rootScope', '$localStorage', '$interval', 'map.factory', 'gps.service', 'geometry.factory', function ($rootScope, $localStorage, $interval, _map, _gps, _geometry) {
        return {
            restrict: "A", // Only usable as an attribute  
            scope: {
                layer: '=trackMe'
            },
            link: function (scope, elem, attr, ngModel) {

                // Is functionality active
                scope.active = false;

                // Interval
                scope.interval = null;

                // Last positions
                scope.positions = [];

                // Current feature
                scope.trackFeature = null;

                // Added features
                scope.trackFeatures = [];

                // Vector layer to draw onto
                scope.vector = null;

                // Calculate distance
                scope.distance = function (pos1, pos2) {
                    return ((new ol.geom.LineString([pos1, pos2]).getLength() * 100) / 100);
                }

                // Record GPS track
                scope.track = function () {

                    // Current GPS position
                    var position = _.clone(_gps.position());

                    // DEMO
                    //if (scope.positions.length > 0) {

                    //    if (scope.positions.length == 1) {
                    //        position[0] = _.last(scope.positions)[0] + 50;
                    //        position[1] = _.last(scope.positions)[1] + 50;
                    //    }

                    //    if (scope.positions.length == 2) {
                    //        position[0] = _.last(scope.positions)[0] - 50;
                    //        position[1] = _.last(scope.positions)[1] + 100;
                    //    }

                    //    if (scope.positions.length == 3) {
                    //        position[0] = _.last(scope.positions)[0] - 50;
                    //        position[1] = _.last(scope.positions)[1] - 100;
                    //    }
                    //}

                    // Skip if last position is the same as the current position
                    if (scope.lastPosition != null && (scope.distance(position, scope.lastPosition) < $rootScope.settings.gpsTrackTolerance))
                        return;

                    // Last position
                    scope.lastPosition = position;

                    // Set last position
                    scope.positions.push(position);

                    // Point
                    if (scope.layer.TypeName == 'PointStyle') {
                        scope.trackFeature = scope.createFeature();
                        scope.trackFeature.setGeometry(new ol.geom.Point(position));
                        scope.trackFeatures.push(scope.trackFeature);
                        scope.vector.getSource().addFeatures([scope.trackFeature]);
                    }

                    // LineString
                    if (scope.layer.TypeName == 'LineStyle' && scope.positions.length > 1) {
                        if (scope.trackFeature == null) {
                            scope.trackFeature = scope.createFeature();
                            scope.trackFeatures.push(scope.trackFeature);
                            scope.vector.getSource().addFeatures([scope.trackFeature]);
                        }
                        scope.trackFeature.setGeometry(new ol.geom.LineString(scope.positions));
                    }

                    // Polygon
                    if (scope.layer.TypeName == 'PolygonStyle' && scope.positions.length > 1) {
                        if (scope.trackFeature == null) {
                            scope.trackFeature = scope.createFeature();
                            scope.trackFeatures.push(scope.trackFeature);
                            scope.vector.getSource().addFeatures([scope.trackFeature]);
                        }
                        scope.trackFeature.setGeometry(new ol.geom.Polygon([scope.positions]));
                    }
                }

                // Create a new feature
                scope.createFeature = function () {

                    // New feature
                    var feature = new ol.Feature();

                    // Set custom properties
                    feature.setProperties({
                        layer: scope.layer,
                        CdoId: scope.layer.CdoId,
                        SpecId: null,
                        ProjectHandle: $rootScope.settings.defaultProjectHandle,
                        Handle: -1,
                        GpsTrack: true
                    });

                    // Return feature
                    return feature;
                }

                // Start
                scope.start = function () {
                    // Active
                    angular.element(elem[0].children[0]).addClass('fa-stop-circle-o');
                    angular.element(elem[0].parentElement).addClass('active');

                    // Start immediate
                    scope.track();

                    // Start interval, every x-seconds check GPS position
                    scope.interval = $interval(scope.track, ($rootScope.settings.gpsTrackInterval * 1000));
                }

                // Stop
                scope.stop = function (save) {

                    // Stop interval
                    if (!_.isNull(scope.interval))
                        $interval.cancel(scope.interval);

                    if (save) {
                        // Save each feature
                        _.each(scope.trackFeatures, function (feature) {
                            _geometry.insertIndexedDB(feature).then(function () {
                                // Update dirty
                                $rootScope.$broadcast('application:tasks:dirty');
                            });
                        });
                    } else {
                        // Remove features from vector layer                    
                        _.each(scope.trackFeatures, function (feature) {
                            scope.vector.getSource().removeFeature(feature);
                        });
                    }

                    // Inactive
                    angular.element(elem[0].children[0]).removeClass('fa-stop-circle-o');
                    angular.element(elem[0].parentElement).removeClass('active');

                    // Clear features
                    scope.trackFeatures = [];
                    // Clear feature
                    scope.trackFeature = null;
                    // Clear positions
                    scope.positions = [];
                    // Clear last position
                    scope.lastPosition = null;
                    // Clear interval
                    scope.interval = null;
                    // Not active
                    scope.active = false;
                }

                // On click       
                elem.on('click', function (e) {
                    scope.$apply(function () {
                        // Activate or deactivate
                        scope.active = !scope.active;

                        // Start / Stop
                        if (scope.active) {
                            scope.start()
                        } else {
                            scope.stop(true); // Stop and save
                        }
                    });
                });

                // Watch selected layer
                scope.$watch(function () { return scope.layer; }, function (newValue, oldValue) {
                    // If running
                    if (scope.active)
                        scope.stop(false);
                    // Clear vector
                    scope.vector = null
                    // Is right layer selected?
                    if (!_.isNull(scope.layer) && scope.layer.CdoId == $rootScope.settings.gpsTrackLayer)
                        scope.vector = _map.layers.vector(scope.layer.CdoId);
                });

                // On destroy panel
                scope.$on('$destroy', function () {
                    // If running
                    if (scope.active)
                        scope.stop(false);
                    // Clear vector
                    scope.vector = null
                });
            }
        }
    }]);

})();