(function () {

    // Strict
    'use strict';

    // Factory
    angular.module('app').factory('wms.factory', ['$rootScope', '$localStorage', '$q', '$http', function ($rootScope, $localStorage, $q, $http) {
        return {
            getTile: function (uri) {
                var deferred = $q.defer();
                $http.get($rootScope.settings + "proxy?uri=" + encodeURIComponent(uri), {
                    responseType: "arraybuffer"
                }).success(function (data, status, headers, config) {
                    deferred.resolve(arrayBufferToBTOA(data));
                });
                return deferred.promise;
            },
            getMap: function (uri, wmsUsername, wmsPassword) {                

                var deferred = $q.defer();
                var headers = {};;
                
                // If authorization required (Basic Authentication)
                if (!_.isNull(wmsUsername) && (!_.isNull(wmsPassword)) && !_.isEmpty(wmsUsername) && !_.isEmpty(wmsPassword)) {
                    headers = { 'Authorization': 'Basic ' + btoa(wmsUsername + ":" + wmsPassword) }
                }
               
                // Send request to the proxy
                $http.get($rootScope.settings.webserviceUrl + "proxy?uri=" + encodeURIComponent(uri), {
                    headers: headers,
                    responseType: "arraybuffer"
                }).success(function (data, status, headers, config) {
                    deferred.resolve(arrayBufferToBTOA(data));
                });

                return deferred.promise;
            }
        }

        // Convert arrayBuffer to base64
        function arrayBufferToBTOA(data) {
            var binary = '';
            var buffer = data;
            var bytes = new Uint8Array(buffer);
            for (var i = 0; i < bytes.byteLength; i++) {
                binary += String.fromCharCode(bytes[i])
            };
            return window.btoa(binary);
        }

    }]);

}());