(function () {

    // Strict
    'use strict';

    // Factory
    angular.module('app').factory('spatialreference.factory', ['$filter', function ($filter) {
        return {
            all: function () {

                // Array
                var retval = [];
                    
                // Add spatial references
                retval.push({ SpatialReferenceId:-1, Name: $filter('translate')('labels.none') });
                retval.push({ SpatialReferenceId:0, Name: $filter('translate')('labels.unknown') });
                retval.push({ SpatialReferenceId:1, Name: "Cartesian" });
                retval.push({ SpatialReferenceId:2393, Name: "FinlandUcs" });
                retval.push({ SpatialReferenceId:4326, Name: "WGS84" });
                retval.push({ SpatialReferenceId:27700, Name: "OSGB1936" });
                retval.push({ SpatialReferenceId:28992, Name: "RD" });
                retval.push({ SpatialReferenceId:31370, Name: "BelgeLambert72" });
                retval.push({ SpatialReferenceId:31466, Name: "GaussKrugerZone2" });
                retval.push({ SpatialReferenceId:31467, Name: "GaussKrugerZone3" });
                retval.push({ SpatialReferenceId:31468, Name: "GaussKrugerZone4" });
                retval.push({ SpatialReferenceId:31469, Name: "GaussKrugerZone5" });
                retval.push({ SpatialReferenceId:32601, Name: "UtmZone1N" });
                retval.push({ SpatialReferenceId:32602, Name: "UtmZone2N" });
                retval.push({ SpatialReferenceId:32603, Name: "UtmZone3N" });
                retval.push({ SpatialReferenceId:32604, Name: "UtmZone4N" });
                retval.push({ SpatialReferenceId:32605, Name: "UtmZone5N" });
                retval.push({ SpatialReferenceId:32606, Name: "UtmZone6N" });
                retval.push({ SpatialReferenceId:32607, Name: "UtmZone7N" });
                retval.push({ SpatialReferenceId:32608, Name: "UtmZone8N" });
                retval.push({ SpatialReferenceId:32609, Name: "UtmZone9N" });
                retval.push({ SpatialReferenceId:32610, Name: "UtmZone10N" });
                retval.push({ SpatialReferenceId:32611, Name: "UtmZone11N" });
                retval.push({ SpatialReferenceId:32612, Name: "UtmZone12N" });
                retval.push({ SpatialReferenceId:32613, Name: "UtmZone13N" });
                retval.push({ SpatialReferenceId:32614, Name: "UtmZone14N" });
                retval.push({ SpatialReferenceId:32615, Name: "UtmZone15N" });
                retval.push({ SpatialReferenceId:32616, Name: "UtmZone16N" });
                retval.push({ SpatialReferenceId:32617, Name: "UtmZone17N" });
                retval.push({ SpatialReferenceId:32618, Name: "UtmZone18N" });
                retval.push({ SpatialReferenceId:32619, Name: "UtmZone19N" });
                retval.push({ SpatialReferenceId:32620, Name: "UtmZone20N" });
                retval.push({ SpatialReferenceId:32621, Name: "UtmZone21N" });
                retval.push({ SpatialReferenceId:32622, Name: "UtmZone22N" });
                retval.push({ SpatialReferenceId:32623, Name: "UtmZone23N" });
                retval.push({ SpatialReferenceId:32624, Name: "UtmZone24N" });
                retval.push({ SpatialReferenceId:32625, Name: "UtmZone25N" });
                retval.push({ SpatialReferenceId:32626, Name: "UtmZone26N" });
                retval.push({ SpatialReferenceId:32627, Name: "UtmZone27N" });
                retval.push({ SpatialReferenceId:32628, Name: "UtmZone28N" });
                retval.push({ SpatialReferenceId:32629, Name: "UtmZone29N" });
                retval.push({ SpatialReferenceId:32630, Name: "UtmZone30N" });
                retval.push({ SpatialReferenceId:32631, Name: "UtmZone31N" });
                retval.push({ SpatialReferenceId:32632, Name: "UtmZone32N" });
                retval.push({ SpatialReferenceId:32633, Name: "UtmZone33N" });
                retval.push({ SpatialReferenceId:32634, Name: "UtmZone34N" });
                retval.push({ SpatialReferenceId:32635, Name: "UtmZone35N" });
                retval.push({ SpatialReferenceId:32636, Name: "UtmZone36N" });
                retval.push({ SpatialReferenceId:32637, Name: "UtmZone37N" });
                retval.push({ SpatialReferenceId:32638, Name: "UtmZone38N" });
                retval.push({ SpatialReferenceId:32639, Name: "UtmZone39N" });
                retval.push({ SpatialReferenceId:32640, Name: "UtmZone40N" });
                retval.push({ SpatialReferenceId:32641, Name: "UtmZone41N" });
                retval.push({ SpatialReferenceId:32642, Name: "UtmZone42N" });
                retval.push({ SpatialReferenceId:32643, Name: "UtmZone43N" });
                retval.push({ SpatialReferenceId:32644, Name: "UtmZone44N" });
                retval.push({ SpatialReferenceId:32645, Name: "UtmZone45N" });
                retval.push({ SpatialReferenceId:32646, Name: "UtmZone46N" });
                retval.push({ SpatialReferenceId:32647, Name: "UtmZone47N" });
                retval.push({ SpatialReferenceId:32648, Name: "UtmZone48N" });
                retval.push({ SpatialReferenceId:32649, Name: "UtmZone49N" });
                retval.push({ SpatialReferenceId:32650, Name: "UtmZone50N" });
                retval.push({ SpatialReferenceId:32651, Name: "UtmZone51N" });
                retval.push({ SpatialReferenceId:32652, Name: "UtmZone52N" });
                retval.push({ SpatialReferenceId:32653, Name: "UtmZone53N" });
                retval.push({ SpatialReferenceId:32654, Name: "UtmZone54N" });
                retval.push({ SpatialReferenceId:32655, Name: "UtmZone55N" });
                retval.push({ SpatialReferenceId:32656, Name: "UtmZone56N" });
                retval.push({ SpatialReferenceId:32657, Name: "UtmZone57N" });
                retval.push({ SpatialReferenceId:32658, Name: "UtmZone58N" });
                retval.push({ SpatialReferenceId:32659, Name: "UtmZone59N" });
                retval.push({ SpatialReferenceId:32660, Name: "UtmZone60N" });
                retval.push({ SpatialReferenceId:32661, Name: "UPS_North" });
                retval.push({ SpatialReferenceId:32701, Name: "UtmZone1S" });
                retval.push({ SpatialReferenceId:32702, Name: "UtmZone2S" });
                retval.push({ SpatialReferenceId:32703, Name: "UtmZone3S" });
                retval.push({ SpatialReferenceId:32704, Name: "UtmZone4S" });
                retval.push({ SpatialReferenceId:32705, Name: "UtmZone5S" });
                retval.push({ SpatialReferenceId:32706, Name: "UtmZone6S" });
                retval.push({ SpatialReferenceId:32707, Name: "UtmZone7S" });
                retval.push({ SpatialReferenceId:32708, Name: "UtmZone8S" });
                retval.push({ SpatialReferenceId:32709, Name: "UtmZone9S" });
                retval.push({ SpatialReferenceId:32710, Name: "UtmZone10S" });
                retval.push({ SpatialReferenceId:32711, Name: "UtmZone11S" });
                retval.push({ SpatialReferenceId:32712, Name: "UtmZone12S" });
                retval.push({ SpatialReferenceId:32713, Name: "UtmZone13S" });
                retval.push({ SpatialReferenceId:32714, Name: "UtmZone14S" });
                retval.push({ SpatialReferenceId:32715, Name: "UtmZone15S" });
                retval.push({ SpatialReferenceId:32716, Name: "UtmZone16S" });
                retval.push({ SpatialReferenceId:32717, Name: "UtmZone17S" });
                retval.push({ SpatialReferenceId:32718, Name: "UtmZone18S" });
                retval.push({ SpatialReferenceId:32719, Name: "UtmZone19S" });
                retval.push({ SpatialReferenceId:32720, Name: "UtmZone20S" });
                retval.push({ SpatialReferenceId:32721, Name: "UtmZone21S" });
                retval.push({ SpatialReferenceId:32722, Name: "UtmZone22S" });
                retval.push({ SpatialReferenceId:32723, Name: "UtmZone23S" });
                retval.push({ SpatialReferenceId:32724, Name: "UtmZone24S" });
                retval.push({ SpatialReferenceId:32725, Name: "UtmZone25S" });
                retval.push({ SpatialReferenceId:32726, Name: "UtmZone26S" });
                retval.push({ SpatialReferenceId:32727, Name: "UtmZone27S" });
                retval.push({ SpatialReferenceId:32728, Name: "UtmZone28S" });
                retval.push({ SpatialReferenceId:32729, Name: "UtmZone29S" });
                retval.push({ SpatialReferenceId:32730, Name: "UtmZone30S" });
                retval.push({ SpatialReferenceId:32731, Name: "UtmZone31S" });
                retval.push({ SpatialReferenceId:32732, Name: "UtmZone32S" });
                retval.push({ SpatialReferenceId:32733, Name: "UtmZone33S" });
                retval.push({ SpatialReferenceId:32734, Name: "UtmZone34S" });
                retval.push({ SpatialReferenceId:32735, Name: "UtmZone35S" });
                retval.push({ SpatialReferenceId:32736, Name: "UtmZone36S" });
                retval.push({ SpatialReferenceId:32737, Name: "UtmZone37S" });
                retval.push({ SpatialReferenceId:32738, Name: "UtmZone38S" });
                retval.push({ SpatialReferenceId:32739, Name: "UtmZone39S" });
                retval.push({ SpatialReferenceId:32740, Name: "UtmZone40S" });
                retval.push({ SpatialReferenceId:32741, Name: "UtmZone41S" });
                retval.push({ SpatialReferenceId:32742, Name: "UtmZone42S" });
                retval.push({ SpatialReferenceId:32743, Name: "UtmZone43S" });
                retval.push({ SpatialReferenceId:32744, Name: "UtmZone44S" });
                retval.push({ SpatialReferenceId:32745, Name: "UtmZone45S" });
                retval.push({ SpatialReferenceId:32746, Name: "UtmZone46S" });
                retval.push({ SpatialReferenceId:32747, Name: "UtmZone47S" });
                retval.push({ SpatialReferenceId:32748, Name: "UtmZone48S" });
                retval.push({ SpatialReferenceId:32749, Name: "UtmZone49S" });
                retval.push({ SpatialReferenceId:32750, Name: "UtmZone50S" });
                retval.push({ SpatialReferenceId:32751, Name: "UtmZone51S" });
                retval.push({ SpatialReferenceId:32752, Name: "UtmZone52S" });
                retval.push({ SpatialReferenceId:32753, Name: "UtmZone53S" });
                retval.push({ SpatialReferenceId:32754, Name: "UtmZone54S" });
                retval.push({ SpatialReferenceId:32755, Name: "UtmZone55S" });
                retval.push({ SpatialReferenceId:32756, Name: "UtmZone56S" });
                retval.push({ SpatialReferenceId:32757, Name: "UtmZone57S" });
                retval.push({ SpatialReferenceId:32758, Name: "UtmZone58S" });
                retval.push({ SpatialReferenceId:32759, Name: "UtmZone59S" });
                retval.push({ SpatialReferenceId:32760, Name: "UtmZone60S" });
                retval.push({ SpatialReferenceId:32761, Name: "UPS_South" });

                // Return 
                return retval;
            }
        };
    }]);

}());