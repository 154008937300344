(function () {

    // Strict
    'use strict';

    // Factory
    angular.module('app').factory('db.factory', ['$window', '$q', '$indexedDB', function ($window, $q, $indexedDB) {
        return {
            logs: {
                add: function (log) {
                    return $indexedDB.openStore('Logs', function (store) {
                        return store.insert(log);
                    });
                },
                all: function () {
                    return $indexedDB.openStore('Logs', function (store) {
                        return store.getAll();
                    });
                },
                clear: function () {
                    return $indexedDB.openStore('Logs', function (store) {
                        return store.clear();
                    });
                }
            },
            geometries: {
                upsert: function (geometry) {
                    return $indexedDB.openStore('Geometries', function (store) {
                        return store.upsert(geometry);
                    });
                },
                add: function (geometries) {
                    return $indexedDB.openStore('Geometries', function (store) {
                        return store.insert(geometries);
                    });
                },
                remove: function (key) {
                    return $indexedDB.openStore('Geometries', function (store) {
                        return store.delete(key);
                    });
                },
                get: function (key) {
                    return $indexedDB.openStore('Geometries', function (store) {
                        return store.find(key);
                    });
                },
                all: function () {
                    return $indexedDB.openStore('Geometries', function (store) {
                        return store.getAll();
                    });
                },
                clear: function () {
                    return $indexedDB.openStore('Geometries', function (store) {
                        return store.clear();
                    });
                }
            },
            tasks: {
                upsert: function (tasks) {
                    return $indexedDB.openStore('Tasks', function (store) {
                        return store.upsert(tasks);
                    });
                },
                add: function (tasks) {
                    return $indexedDB.openStore('Tasks', function (store) {
                        return store.insert(tasks);
                    });
                },
                remove: function (key) {
                    return $indexedDB.openStore('Tasks', function (store) {
                        return store.delete(key);
                    });
                },
                get: function (key) {
                    return $indexedDB.openStore('Tasks', function (store) {
                        return store.find(key);
                    });
                },
                all: function () {
                    return $indexedDB.openStore('Tasks', function (store) {
                        return store.getAll();
                    });
                },
                clear: function () {
                    return $indexedDB.openStore('Tasks', function (store) {
                        return store.clear();
                    });
                }
            }
        };
    }]);

}());
