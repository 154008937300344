(function () {

    // Strict
    'use strict';

    // Factory
    angular.module('app').factory('map.factory', ['$rootScope', '$timeout', '$localStorage', '$q', 'wms.factory', 'style.label.factory', function ($rootScope, $timeout, $localStorage, $q, _wms, _label) {

        // Variables
        var self = {}, map = {}, marker = new ol.Feature();

        var resolutions = [156543.03390625, 78271.516953125, 39135.7584765625, 19567.87923828125, 9783.939619140625, 4891.9698095703125, 2445.9849047851562,
            1222.9924523925781, 611.4962261962891, 305.7481130914453, 152.87405654907226, 76.43702827453613, 38.218514137268066,
            19.109257068634033, 9.554628534317017, 4.777314267158505, 2.388657133579254, 1.194328566789627, 0.5971642833948135, 0.2985821416974068, 0.1492910708487034,
            0.0746455354243517, 0.0373227677121758, 0.0186613838560879, 0.009330691928044, 0.004665345964022, 0.002332672982011, 0.0011663364910055, 0.0005831682455027
            , 0.0002915841227514, 0.0001457920613757];

        // Function
        return {
            map: map,
            init: init,
            marker: {
                add: marker_location,
                clear: marker_location_clear
            },
            baselayer: {
                load: baselayer_load
            },
            layers: {
                vector: get_layer,
                wms: get_layer,
                clear: clear_layers,
                style: style
            },
            wms: {
                toggleVisibility: wms_visibility,
            },
            tools: {
                updateSize: updateSize,
                extent: extent,
                setDefaultCenter: setDefaultCenter
            }
        }

        // Initialize map
        function init() {

            // Reference to this
            self = this;

            // Map
            self.map = new ol.Map({
                target: 'map-canvas',
                controls: [],
                interactions: ol.interaction.defaults({ altShiftDragRotate: false, pinchRotate: false, dragPan: true }),
                layers: [
                    new ol.layer.Tile({
                        name: 'baselayer',
                        source: baselayer_source()
                    }),
                    new ol.layer.Vector({
                        name: 'marker',
                        source: new ol.source.Vector({ features: [marker] }),
                        style: (function () {
                            return function (feature, resolution) {
                                return new ol.style.Style({
                                    image: new ol.style.Icon(({
                                        anchor: [10, 32],
                                        anchorXUnits: 'pixels',
                                        anchorYUnits: 'pixels',
                                        opacity: 0.90,
                                        src: './assets/images/marker.png'
                                    }))
                                })
                            }
                        })()
                    })
                ],
                view: new ol.View({
                    //center: ol.proj.fromLonLat(defaultLocation()),
                    zoom: $rootScope.settings.defaultZoom,
                    resolutions: resolutions
                })
            });

            // Default center
            setDefaultCenter();

            // Set ZIndex on marker layer
            get_layer('marker').setZIndex(9999999);

            // Add draw vector layer
            //draw();

            // Add layers
            layers();

            // Initialize geolocation feature
            geolocation();
        }

        function geolocation() {

            var tracking = true;

            // Position Feature
            var position = new ol.Feature();

            // Accuracy Feature
            var accuracy = new ol.Feature({
                style: new ol.style.Style({
                    image: new ol.style.Circle({
                        radius: 6,
                        fill: new ol.style.Fill({
                            color: '#3399CC'
                        }),
                        stroke: new ol.style.Stroke({
                            color: '#ffffff',
                            width: 2
                        })
                    })
                })
            });

            // Vector
            var vector = new ol.layer.Vector({
                map: self.map,
                source: new ol.source.Vector({
                    features: [accuracy, position]
                })
            });

            // Watch GPS position event (from GPS Rover or Geolocation)
            $rootScope.$on("application:gps:position", function (event, geom) {
                if (!_.isNull(geom)) {
                    position.setGeometry(geom);
                    if (tracking) {
                        self.map.getView().setCenter(geom.getCoordinates());
                        tracking = false;
                    }
                }
            });

            // Watch GPS accuracy event (from GPS Rover or Geolocation)
            $rootScope.$on("application:gps:accuracy", function (event, geom) {
                accuracy.setGeometry(geom);
            });
        }

        // Default location
        function setDefaultCenter(pos) {

            // Position
            var position = pos;
            if (_.isUndefined(position) && (!_.isUndefined($rootScope.settings.defaultCenterLon) && !_.isUndefined($rootScope.settings.defaultCenterLat)))
                position = ol.proj.fromLonLat([$rootScope.settings.defaultCenterLon, $rootScope.settings.defaultCenterLat]);
            if (_.isUndefined(position))
                position = ol.proj.fromLonLat([5.107965, 52.055614]); // Geostruct, Winthontlaan 200, Utrecht

            // Update map
            self.map.getView().setCenter(position);
        }

        function wms_visibility() {
            var cdoTypes = _.filter($localStorage.cdotypes, { 'CdoId': 0 });
            _.each(_.filter($localStorage.cdotypes, { 'CdoId': 0 }), function (cdoType) {
                var layer = self.layers.wms(cdoType.Name);
                if (!_.isNull(layer) && !_.isUndefined(layer))
                    layer.setVisible(cdoType.Visible);
            });
        }

        // Add layers
        function layers() {
            // Add each layer
            _.each($localStorage.map.layers, function (layer, index) {

                var obj = null;

                // Vector
                if (layer.CdoId > 0) {
                    obj = new ol.layer.Vector({
                        name: layer.CdoId,
                        source: new ol.source.Vector(),
                        style: (function () {
                            return function (feature, resolution) {                               

                                // Geoline
                                var geoline = feature.get('geoline');

                                // Variables
                                var lineColor = null;
                                var fillColor = null;
                                var specId = feature.get('SpecId');
                                var cdoId = feature.get('CdoId');
                           
                                
                                // Style
                                return style(feature, specId, cdoId, lineColor, fillColor, resolution);
                            }
                        })()
                    });
                } else {

                    // ClassName
                    var className = _.find(layer.ExtendedAttributes, { Name: 'ClassName' });

                    // Skip if not WmsLayer
                    if (_.isUndefined(className) || className.Value != "WmsLayer")
                        return;

                    // WMS parameters
                    var wmsRequestUri = _.find(layer.ExtendedAttributes, { Name: 'WmsRequestUri' }).Value;
                    if (wmsRequestUri.indexOf('?') > -1)
                        wmsRequestUri = wmsRequestUri.split('?')[0];
                    var wmsLayerName = _.find(layer.ExtendedAttributes, { Name: 'WmsLayerName' }).Value;
                    var wmsImageFormat = _.find(layer.ExtendedAttributes, { Name: 'WmsImageFormat' }).Value;
                    var wmsVersion = _.find(layer.ExtendedAttributes, { Name: 'WmsVersion' }).Value;
                    var wmsUsername = _.find(layer.ExtendedAttributes, { Name: 'WmsUserName' });
                    if (!_.isUndefined(wmsUsername))
                        wmsUsername = wmsUsername.Value;
                    var wmsPassword = _.find(layer.ExtendedAttributes, { Name: 'WmsPassword' });
                    if (!_.isUndefined(wmsPassword))
                        wmsPassword = wmsPassword.Value;
                    var wmsTiled = _.find(layer.ExtendedAttributes, { Name: 'WmsTiled' }).Value;

                    // CdoType
                    var cdoType = _.first(_.filter($localStorage.cdotypes, { 'CdoId': 0, 'Name': layer.Name }));

                    // ImageLoadFunction
                    var loadFunction = function (image, url) {
                        // GetMap
                        _wms.getMap(url, wmsUsername, wmsPassword).then(function (data) {
                            image.getImage().src = 'data:' + wmsImageFormat + ';base64,' + data;
                        });
                    };

                    if (wmsTiled == 'False') {
                        // WMS layer
                        obj = new ol.layer.Image({
                            name: layer.Name,
                            visible: cdoType.Visible,
                            source: new ol.source.ImageWMS({
                                url: wmsRequestUri,
                                params: { 'LAYERS': wmsLayerName, 'FORMAT': wmsImageFormat, 'VERSION': wmsVersion },
                                imageLoadFunction: loadFunction
                            })
                        });
                    } else {
                        // WMS Tiled Layer
                        obj = new ol.layer.Tile({
                            name: layer.Name,
                            visible: cdoType.Visible,
                            source: new ol.source.TileWMS({
                                url: wmsRequestUri,
                                params: { 'LAYERS': wmsLayerName, 'TILED': true, 'VERSION': wmsVersion },
                                serverType: 'geoserver',
                                tileLoadFunction: loadFunction
                            })
                        });
                    }
                }

                // Set Min/Max Resolution
                if (layer.MinZoomLevel > 0)
                    obj.setMaxResolution(resolutions[layer.MinZoomLevel]);
                if (layer.MaxZoomLevel > 0)
                    obj.setMinResolution(resolutions[layer.MaxZoomLevel]);

                // Add properties
                obj.setProperties({
                    layerInformation: layer
                });

                // zIndex
                obj.setZIndex(1000000 - index);

                // Add layer
                self.map.addLayer(obj);
            });
        }

        // Get layer
        function get_layer(name) {
            return _.first(_.filter(self.map.getLayers().getArray(), function (layer) {
                return layer.get('name') == name;
            }));
        }

        // Clear vector layers
        function clear_layers() {
            _.each(_.filter($localStorage.map.layers, function (layer) {
                // Only vector layers
                if (layer.CdoId > 0)
                    return layer;
            }), function (layer) {
                get_layer(layer.CdoId).getSource().clear();
            });
        }

        // Get style
        function style(feature, specId, cdoId, lineColor, fillColor, resolution) {

            // Retrieve color
            // 1. CustomView.config
            //      Retrieved from 'API'
            // 2. Spec
            // 3. MapSettings

            // Layer            
            var layer = feature.get('layer');

            // Spec
            if (((_.isNull(lineColor) || _.isEmpty(lineColor)) && (_.isNull(fillColor) || _.isEmpty(fillColor))) && !_.isNull(specId)) {
                // Get Spec from localStorage
                var spec = _.find($localStorage.specs, { SpecId: specId, CdoId: cdoId });
                if (!_.isUndefined(spec) && !_.isNull(spec.Color)) {
                    lineColor = spec.Color;
                    fillColor = spec.Color;
                }
            }

            // Map Setting File
            if ((_.isNull(lineColor) || _.isEmpty(lineColor)) && (_.isNull(fillColor) || _.isEmpty(fillColor))) {
                lineColor = layer.LineColor;
                fillColor = layer.FillForeColor;
            }

            var s = [];
            switch (layer.TypeName) {
                case "PointStyle":
                    s.push(new ol.style.Style({
                        image: shape(layer, lineColor)
                    }));
                    break;
                case "LineStyle":
                    s.push(new ol.style.Style({
                        stroke: new ol.style.Stroke({
                            lineCap: 'square',
                            width: strokeWidth(layer),
                            color: convertHex(lineColor, layer.LineOpacity)
                        })
                    }));
                    break;
                case "PolygonStyle":
                    s.push(new ol.style.Style({
                        stroke: new ol.style.Stroke({
                            lineCap: 'square',
                            width: strokeWidth(layer),
                            color: convertHex(lineColor, layer.LineOpacity)
                        }),
                        fill: new ol.style.Fill({
                            color: convertHex(fillColor, layer.FillForeOpacity)
                        }),
                    }));
                    break;
            };

            // Display labels
            if (layer.ShowLabels) {
                var LabelStyleValue = feature.get('LabelStyleValue');
                _.each(layer.LabelStyles, function (labelStyle, index) {

                    // Text
                    var text = '';
                    if (!_.isUndefined(LabelStyleValue) && !_.isEmpty(LabelStyleValue)) {                        
                        // Label text
                        text = LabelStyleValue.split(',')[index];                                                
                        // Label format
                        if (labelStyle.Format != "") {
                            text = labelStyle.Format.replace("{0}", text);
                        }
                    }

                    // Empty text, skip label
                    if (!_.isEmpty(text)) {
                        // Label
                        var label = _label.getLabel(labelStyle, self.map.getView().getZoom(), text);
                        if (!_.isNull(label)) {
                            s.push(label);
                        }
                    }
                });
            }

            // Trench Content Layer
            if (!_.isUndefined($localStorage.map.trenchContentLayer) && layer.Name == $localStorage.map.trenchContentLayer) {

                var lineStrings = [];                            

                if (feature.getGeometry().getType() == "MultiLineString") {
                    lineStrings = feature.getGeometry().getLineStrings();
                }

                if (feature.getGeometry().getType() == "LineString")
                {
                    lineStrings.push(feature.getGeometry());
                }

                if (lineStrings.length > 0) {
                    _.each(lineStrings, function (lineString) {
                        s.push(new ol.style.Style({
                            geometry: new ol.geom.Point(lineString.getLastCoordinate()),
                            image: new ol.style.Circle({
                                fill: new ol.style.Fill({
                                    color: convertHex(lineColor, layer.LineOpacity)
                                }),
                                points: 4,
                                radius: Math.round(shapeSize({ IsScalable: true, Size: 50 }) * 0.5),
                            })
                        }));
                    });
                }
            }

            // Return style
            return s;
        }

        // ###
        // INTERNAL METHODS
        // ###

        // Add marker to map
        function marker_location(center, zoom, options) {
            // View
            var view = self.map.getView();

            // Set geometry
            marker.setGeometry(new ol.geom.Point(center));

            // Set center
            if (_.isUndefined(options) || _.isNull(options))
                view.setCenter(center);
            else {
                if (options.center)
                    view.setCenter(center);
            }

            // Set zoom
            if (!_.isNull(zoom) && !_.isUndefined(zoom)) {
                view.setZoom(zoom);
            }
        };

        // Clear marker
        function marker_location_clear() {
            marker.setGeometry(undefined);
        }

        // Baselayer set source
        function baselayer_load() {
            // Clear baselayer
            _.each(self.map.getLayers().getArray(), function (layer) {
                if (layer.get('name') && layer.get('name') == 'baselayer') {
                    layer.setSource(baselayer_source());
                }
            });
        };

        // Baselayer source
        function baselayer_source() {
            // Get source
            switch ($rootScope.settings.mapbaselayer) {
                case "bingroad":
                    return new ol.source.BingMaps({
                        key: 'AmnnhCFESkqVj0sLeOu_zbykVN_7Pzs1PdkFDjg0HuMhOBnIaY_SfN5cBmXzPMjU',
                        imagerySet: 'Road',
                        maxZoom: 19
                    });
                    break;
                case "bingaerial":
                    return new ol.source.BingMaps({
                        key: 'AmnnhCFESkqVj0sLeOu_zbykVN_7Pzs1PdkFDjg0HuMhOBnIaY_SfN5cBmXzPMjU',
                        imagerySet: 'Aerial',
                        maxZoom: 19
                    });
                    break;
                case "bingaerialwithlabels":
                    return new ol.source.BingMaps({
                        key: 'AmnnhCFESkqVj0sLeOu_zbykVN_7Pzs1PdkFDjg0HuMhOBnIaY_SfN5cBmXzPMjU',
                        imagerySet: 'AerialWithLabels',
                        maxZoom: 19
                    });
                    break;
                default:
                    return new ol.source.OSM();
                    break;
            }
        };

        // ###
        // TOOLS
        // ###

        // Update map size
        function updateSize() {
            var deferred = $q.defer();
            if (!_.isUndefined(self.map)) {
                // After timeout
                $timeout(function () {
                    self.map.updateSize();
                    deferred.resolve();
                }, 200);
            } else {
                deferred.resolve();
            }
            return deferred.promise;
        }

        // Extent for viewport
        function extent() {

            // Map view
            var view = self.map.getView();

            // Get extent for current viewport
            var extent = view.calculateExtent(self.map.getSize());

            // Transform to EPSG:4326            
            return ol.extent.applyTransform(extent, ol.proj.getTransform("EPSG:3857", "EPSG:4326"));
        }

        // Stroke width
        function strokeWidth(layer) {
            // Variables
            var maxZoom = 22;
            var scaleSize = 1.0;
            var zoom = self.map.getView().getZoom();
            // Is scalable?
            if (layer.IsScalable)
                scaleSize = 1 / (Math.pow(2, maxZoom - zoom));
            var strokeWeight = parseInt(layer.LineWidth * scaleSize);
            if (strokeWeight < 1)
                strokeWeight = 1;
            return strokeWeight;
        }

        // Get shape character and font
        function shape(layer, lineColor) {
            switch (layer.Shape) {
                case 0:
                    // Diamond
                    return new ol.style.RegularShape({
                        fill: new ol.style.Fill({
                            color: convertHex(lineColor, layer.LineOpacity)
                        }),
                        points: 4,
                        radius: Math.round(shapeSize(layer) * 0.5),
                    });
                    break;
                case 1:
                    // Ellipse
                    return new ol.style.Circle({
                        fill: new ol.style.Fill({
                            color: convertHex(lineColor, layer.LineOpacity)
                        }),
                        points: 4,
                        radius: Math.round(shapeSize(layer) * 0.5),
                    });
                    break;
                case 2:
                    // Hexagon
                    return new ol.style.RegularShape({

                        fill: new ol.style.Fill({
                            color: convertHex(lineColor, layer.LineOpacity)
                        }),
                        geometry: ol.interaction.Draw.createRegularPolygon(4),
                        points: 6,
                        radius: Math.round(shapeSize(layer) * 0.5)
                    });
                    break;
                case 3:
                    // Rectangle
                    return new ol.style.RegularShape({
                        fill: new ol.style.Fill({
                            color: convertHex(lineColor, layer.LineOpacity)
                        }),
                        points: 4,
                        radius: Math.round(shapeSize(layer) * 0.5),
                        angle: Math.PI / 4
                    });
                    break;
                case 4:
                    // Pentagon
                    return new ol.style.RegularShape({
                        fill: new ol.style.Fill({
                            color: convertHex(lineColor, layer.LineOpacity)
                        }),
                        points: 5,
                        radius: Math.round(shapeSize(layer) * 0.5),
                    });
                    break;
                case 5:
                    // Star
                    return new ol.style.RegularShape({
                        fill: new ol.style.Fill({
                            color: convertHex(lineColor, layer.LineOpacity)
                        }),
                        points: 5,
                        radius2: Math.round(shapeSize(layer) * 0.2),
                        radius: Math.round(shapeSize(layer) * 0.5),
                        angle: 0
                    });
                    break;
                case 6:
                    // Triangle                  
                    return new ol.style.RegularShape({
                        fill: new ol.style.Fill({
                            color: convertHex(lineColor, layer.LineOpacity)
                        }),
                        points: 3,
                        radius: Math.round(shapeSize(layer) * 0.5),
                    });
                    break;
            }
        }

        // Shape size
        function shapeSize(layer) {
            // Variables
            var maxZoom = 22;
            var scaleSize = 1.0;
            var zoom = self.map.getView().getZoom();
            // Is scalable?
            if (layer.IsScalable)
                scaleSize = 1 / (Math.pow(2, maxZoom - zoom));
            var shapeSize = parseInt(layer.Size * scaleSize);
            if (shapeSize < 1)
                shapeSize = 1;
            return shapeSize;
        }

    }]);

}());