(function () {

    // Strict
    'use strict';

    angular.module('app').directive('signaturePad', ['$window', '$rootScope', '$timeout', '$q', 'compress.service', function ($window, $rootScope, $timeout, $q, _compress) {

        return {
            restrict: 'EA',
            replace: true,
            require: '^ngModel',
            scope: {
                ngModel: '=',
                ngRequired: '=',
                ngDisabled: '=',
                penColor: '@',
                takePicture: '=',
                name: '@',
                width: '=',
                height: '='
            },
            templateUrl: './app/directives/signature.directive.html',
            controller: ['$scope', function ($scope) {

                // Watch ng-disabled
                $scope.$watch(function () { return $scope.ngDisabled }, function (newValue, oldValue) {
                    if (_.isUndefined($scope.ngDisabled) || (!_.isUndefined($scope.ngDisabled) && !$scope.ngDisabled)) {
                        $scope.signaturePad.on();
                    } else {
                        $scope.signaturePad.off();
                    }
                }, true);

                // Take photo
                $scope.photo = function () {
                    $scope.input.click();
                };

                // Load image with promise
                $scope.loadImg = function (file) {
                    var deferred = $q.defer();
                    var img = document.createElement('img');
                    img.onload = function () {
                        deferred.resolve({ width: this.width, height: this.height });
                    }
                    img.src = file;
                    return deferred.promise;
                }

                
            }],
            link: function (scope, element, attr, ctrl) {

                // ngModel required
                if (!ctrl) return;

                // Canvas
                var canvas = element.find('canvas')[0];

                // File upload
                if (scope.takePicture) {
                    scope.input = document.createElement('input');
                    scope.input.type = 'file';
                    scope.input.onchange = function () {
                        if (scope.input.files.length > 0) {
                            // Clear signaturePad
                            scope.signaturePad.clear();
                            // Upload file
                            var f = scope.input.files[0], r = new FileReader();
                            r.onloadend = function (e) {
                                // Set model value
                                scope.$apply(function () {
                                   
                                    // Set model
                                    ctrl.$setViewValue(e.target.result);

                                    // Load image
                                    scope.loadImg(e.target.result).then(function (img) {

                                        // Calculate image ratio
                                        var ratio = 1;
                                        var width = canvas.offsetWidth * Math.max($window.devicePixelRatio || 1, 1);
                                        var height = canvas.offsetHeight * Math.max($window.devicePixelRatio || 1, 1);

                                        // Calculate ratio by image size
                                        if (img.height > img.width) {
                                            ratio = (img.width / img.height);
                                        } else if (img.width > img.height) {
                                            ratio = (img.height / img.width);
                                        }

                                        // If image is still bigger then canvas width, recalculate ratio
                                        if ((img.width * ratio) > width)
                                            ratio = canvas.offsetWidth / width;
                                        else if ((img.width * ratio) < width)
                                            ratio = width / canvas.offsetWidth;

                                        // Calculate canvas size
                                        width = img.width * ratio;
                                        height = img.height * ratio;

                                        // Update canvas                                    
                                        canvas.width = width;
                                        canvas.height = height;
                                        
                                        // Render control
                                        ctrl.$render();

                                        // Resize
                                        scope.onResize();
                                    });

                                    // Set signature
                                    scope.signaturePad.fromDataURL(e.target.result);
                                });
                                // Clear input
                                scope.input.value = '';
                            }
                            r.readAsDataURL(f);
                        }
                    }
                }

                // PenColor
                if (_.isUndefined(scope.penColor))
                    scope.penColor = '#000000';

                // Set canvas size
                scope.onResize = function (b) {
                    
                    // Calculate image ratio
                    var ratio = Math.max($window.devicePixelRatio || 1, 1);
                    var width = canvas.offsetWidth * ratio;
                    var height = canvas.offsetHeight * ratio;

                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext("2d").scale(ratio, ratio);

                    // Render control
                    ctrl.$render();
                }

                // Trash
                scope.trash = function () {
                    ctrl.$setViewValue('');
                    ctrl.$render();
                }

                // Bind on resize event
                angular.element($window).bind('resize', function () {
                    scope.onResize();
                });

                // Bind on panel resize event
                $rootScope.$on('application:panel:resize', function () {
                    scope.onResize();
                });

                // SignaturePad
                scope.signaturePad = new SignaturePad(canvas, { penColor: scope.penColor });

                // Signature finish
                scope.signaturePad.onEnd = function () {                                        
                    // Set value
                    ctrl.$setViewValue(scope.signaturePad.toDataURL());                   
                };

                // Validate                            
                ctrl.$parsers.unshift(function (viewValue) {
                    if (scope.ngRequired)
                        ctrl.$setValidity('required', !scope.signaturePad.isEmpty());
                    // Return value
                    return viewValue;
                });

                // Render
                ctrl.$render = function () {
                    $timeout(function () {                        
                        // If empty clear cavnas
                        if (_.isEmpty(ctrl.$viewValue)) {
                            scope.signaturePad.clear();
                        } else {
                            scope.signaturePad.fromDataURL(ctrl.$viewValue);
                        }
                    });
                };

                // Update canvas on load image
                $timeout(function () {
                    if (!_.isUndefined(scope.ngModel) && !_.isEmpty(scope.ngModel)) {
                        // Load image                               
                        scope.loadImg(scope.ngModel).then(function (img) {

                            // Calculate image ratio
                            var ratio = 1;
                            var width = canvas.offsetWidth * Math.max($window.devicePixelRatio || 1, 1);
                            var height = canvas.offsetHeight * Math.max($window.devicePixelRatio || 1, 1);

                            // Set width/height
                            if (width == 0 && height == 0) {
                                width = img.width;
                                height = img.height;
                            }

                            // Calculate ratio by image size
                            if (img.height > img.width) {
                                ratio = (img.width / img.height);
                            } else if (img.width > img.height) {
                                ratio = (img.height / img.width);
                            }

                            // If image is still bigger then canvas width, recalculate ratio
                            if ((img.width * ratio) > width)
                                ratio = canvas.offsetWidth / width;
                            else if ((img.width * ratio) < width)
                                ratio = width / canvas.offsetWidth;

                            // Calculate canvas size
                            width = img.width * ratio;
                            height = img.height * ratio;

                            // Update canvas                                    
                            canvas.width = width;
                            canvas.height = height;

                            // Resize
                            scope.onResize();
                        });
                    } else {
                        // Resize
                        scope.onResize();
                    }
                });
            }
        };
    }]);

}());