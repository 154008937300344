(function () {

    // Strict
    'use strict';

    // Sce Filter 'trustAsHtml'
    angular.module('app').filter('trust', ['$sce', function ($sce) {
        return function (text) {
            // Defaults to treating trusted text as `html`
            return $sce.trustAsHtml(text);
        }
    }]);    

})();