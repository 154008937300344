(function () {

    // Strict
    'use strict';

    // Controller
    angular.module('app').controller('log.controller', ['$scope', '$location', 'db.factory', '$uibModalInstance', function ($scope, $location, _db, $uibModalInstance) {

        // Page
        $scope.page = {
            itemsPerPage: 10
        }

        // ErrorLog
        $scope.errors = [];
        _db.logs.all().then(function (errors) {
            $scope.errors = errors;
        });

        // Clear
        $scope.clear = function () {
            // Clear errors
            $scope.errors = [];
            // Clear indexedDB
            _db.logs.clear();
        }

        // Close
        $scope.close = function () {
            $uibModalInstance.dismiss('cancel');
        }

    }]);

}());