(function () {

    'use strict';

    // Draw
    angular.module('app').directive('autoBlur', function () {
        return {
            scope: {
                blur: '=autoBlur'
            },
            link: function (scope, elem) {

                elem.on('click', function (e) {
                    if (scope.blur)
                        elem[0].blur();
                });

                scope.$watch(function () {
                    return scope.blur;
                }, function (newValue, oldValue) {
                    if (newValue) {
                        elem[0].blur();
                    }
                });
            }
        };
    });

}());