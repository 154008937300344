(function () {
    'use strict';

    angular.module('app').factory('sync.service', ['$rootScope', '$q', '$filter', '$confirm', 'geometry.factory', 'task.factory', 'toastr', 'modal.service', 'db.factory', function ($rootScope, $q, $filter, $confirm, _geometry, _task, toastr, _modal, _db) {
        return {
            all: function (geom, task) {
                var deferred = $q.defer();

                // Sync geometries defined
                if (_.isUndefined(geom))
                    geom = _geometry.commit;
                // Sync tasks defined
                if (_.isUndefined(task))
                    task = _task.commit;

                $q.all([geom(), task()]).then(function (result) {
                    var result = { error: (!_.isUndefined(result[0]) && result[0].error == true) || (!_.isUndefined(result[1]) && result[1].error == true) };
                    // Display error
                    if (result.error == true) {
                        // Display error
                        toastr.error($filter('translate')('labels.commiterror'));
                    } else {
                        // Display successs
                        toastr.success($filter('translate')('labels.commitsuccess'));
                    }                   
                    // Update dirty
                    $rootScope.$broadcast('application:tasks:dirty');
                    // Resolve
                    deferred.resolve(result);
                });
                return deferred.promise;
            },
            geometry: function () {
                return this.all(_geometry.commit);
            },
            tasks: function () {
                return this.all(_task.commit);
            },
            dirty: function () {
                var deferred = $q.defer();
                $q.all([_geometry.dirty(), _task.dirty()]).then(function (results) {
                    deferred.resolve(results[0].length > 0 || results[1].length > 0);
                })
                return deferred.promise;
            },
            syncAllWithConfirm: function (text) {

                var dialog = null;
                var self = this;
                var defer = $q.defer();

                // Text
                if (_.isUndefined(text) || _.isNull(text))
                    text = 'labels.syncfounddirtywithclear';

                // Sync if any dirty
                self.dirty().then(function (result) {
                    // Data versturen
                    if (result == true) {
                        // Confirm
                        $confirm({ backdrop: true, text: $filter('translate')(text), title: $filter('translate')('labels.syncsubmittitle'), ok: $filter('translate')('buttons.yes'), cancel: $filter('translate')('buttons.no') }, { backdrop: 'static' }).then(function (result) {

                            // Show loading
                            $rootScope.$broadcast('loading:show');

                            // Sending data
                            dialog = _modal.alert({ text: $filter('translate')('labels.sendingdatapleasewait') });

                            // Sync task and geometry
                            self.all().then(function (result) {
                                // Hide loading
                                $rootScope.$broadcast('loading:hide');
                                // Close dialog
                                dialog.dismiss('close');
                                dialog = null;
                                // Resolve
                                defer.resolve(result);
                            });

                        }, function () {
                            // Resolve
                            defer.resolve({ error: false });
                        });
                    } else {
                        // Resolve
                        defer.resolve({ error: false });
                    }
                });

                // Return promise
                return defer.promise;
            },
            dismissAllWithConfirm: function () {

                var self = this;
                var defer = $q.defer();

                // Sync if any dirty
                self.dirty().then(function (result) {
                    // Data versturen
                    if (result == true) {
                        // Confirm
                        $confirm({ backdrop: true, text: $filter('translate')('labels.cleartaskgeometry'), title: $filter('translate')('labels.cleartaskgeometrytitle'), ok: $filter('translate')('buttons.yes'), cancel: $filter('translate')('buttons.no') }, { backdrop: 'static' }).then(function (result) {
                            // Clear task/geometry
                            $q.all([_db.tasks.clear(), _db.geometries.clear()]).then(function () {
                                defer.resolve();
                            });
                        }, function () {
                            // Resolve
                            defer.resolve();
                        });
                    } else {
                        // Resolve
                        defer.resolve();
                    }
                });

                // Return promise
                return defer.promise;
            }
        }
    }]);
})();