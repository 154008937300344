(function () {

    'use strict';

    angular.module('app').filter('hideExtension', [function () {
        return function (fileName) {
            return fileName.replace(/\.[^/.]+$/, "");
        }
    }]);

})();