
angular.module('app').factory('geometry.factory', ['$rootScope', '$q', '$localStorage', 'map.factory', 'db.factory', 'api.factory', function ($rootScope, $q, $localStorage, _map, _db, _api) {

    // Variables
    var self = {};

    return {
        fromIndexedDB: fromIndexedDB,
        clearDirtyIndexedDB: clearDirtyIndexedDB,
        insertIndexedDB: insertIndexedDB,
        updateIndexedDB: updateIndexedDB,
        upSertIndexedDB: upSertIndexedDB,
        toPoints: toPoints,
        dirty: dirty,
        commit: commit
    }

    // Commit geometries
    function commit() {
        // Defer
        var defer = $q.defer();
        
        // Get all geometries from indexedDB
        dirty().then(function (geometries) {
            // Commit geometries
            _api.commitGeometries(geometries).then(function (result) {

                // Update each feature 'Dirty'
                var grouped = _.groupBy(geometries, function (geometry) { return geometry.CdoId; });
                _.each(grouped, function (geolines, key) {
                    // Find layer
                    var layer = _map.layers.vector(key);
                    var features = layer.getSource().getFeatures();
                    _.each(geolines, function (geoline) {                        
                        var feature = _.find(features, function (feature) {                            
                            return feature.get("key") == geoline.GeometryId;
                        });                          
                        if (!_.isUndefined(feature)) {                           
                            feature.set('Dirty', false);
                            feature.set('Handle', geoline.Handle);
                        }
                    })
                });

                // Resolve
                defer.resolve(result);
            });
        }, function (error) {            
            // Error
            defer.resolve({ error: true });
        });

        // Return promise
        return defer.promise;
    }

    // Any geometry dirty
    function dirty() {
        return _db.geometries.all().then(function (geometries) {
            return _.filter(geometries, { Dirty: true });
        });
    }

    function upSertIndexedDB(feature) {
        if (_.isUndefined(feature.get('key'))) {
            return insertIndexedDB(feature);
        }
        else {
            return updateIndexedDB(feature);
        }
    }

    // Add feature to indexedDB
    function insertIndexedDB(feature) {
        var deferred = $q.defer();

        // Feature dirty
        feature.set('Dirty', true);
        
        var geometry = {
            CdoId: feature.get('CdoId'),
            Dirty: true,
            Points: toPoints(feature),
            Handle: -1,
            ProjectHandle: feature.get('ProjectHandle'),
            SpecId: feature.get('SpecId'),
            DisplayFields: !_.isUndefined(feature.get('DisplayFields')) ? feature.get('DisplayFields') : [],
            Files: !_.isUndefined(feature.get('Files')) ? feature.get('Files') : [],
            LabelStyleValue: !_.isUndefined(feature.get('LabelStyleValue')) ? feature.get('LabelStyleValue') : ''           
        }

        // Gps Track
        if (!_.isUndefined(feature.get('GpsTrack')))
            geometry.GpsTrack = feature.get('GpsTrack');

        // Add to indexedDB
        _db.geometries.add([geometry]).then(function (result) {
            // Set custom properties
            feature.set('key', _.first(result));
            // Resolve
            deferred.resolve();
        });

        return deferred.promise;
    }

    // Update feature
    function updateIndexedDB(feature) {
        var deferred = $q.defer();

        // Get geometry from indexedDB
        _db.geometries.get(feature.get('key')).then(function (geometry) {

            // Feature Dirty
            feature.set('Dirty', true);

            // Dirty
            geometry.Dirty = true;

            // DisplayFields
            geometry.DisplayFields = !_.isUndefined(feature.get('DisplayFields')) ? feature.get('DisplayFields') : [];

            // Files
            geometry.Files = !_.isUndefined(feature.get('Files')) ? feature.get('Files') : [];

            // Update Points
            geometry.Points = toPoints(feature);

            // SpecId
            if (!_.isUndefined(feature.get('SpecId')))
                geometry.SpecId = feature.get('SpecId');

            // Gps Track
            if (!_.isUndefined(feature.get('GpsTrack')))
                geometry.GpsTrack = feature.get('GpsTrack');

            // LabelStyleValue
            if (!_.isUndefined(feature.get('LabelStyleValue')))
                geometry.LabelStyleValue = feature.get('LabelStyleValue');

            // Update indexedDB
            _db.geometries.upsert(geometry).then(function () {
                // Resolve
                deferred.resolve();
            });
        });
        return deferred.promise;
    }

    // Create points from feature geometry
    function toPoints(feature) {

        // Points
        var points = [];

        // Coordinate
        var coordinates = feature.getGeometry().getCoordinates();

        switch (feature.getGeometry().getType()) {
            case "Point":
                var lonlat = ol.proj.toLonLat(coordinates);
                points.push({ 'Latitude': lonlat[1], 'Longitude': lonlat[0], 'Altitude': 0 });
                break;
            case "MultiLineString":
                _.merge(points, _.map(_.first(coordinates), function (coordinate) {
                    var lonlat = ol.proj.toLonLat(coordinate);
                    return { 'Latitude': lonlat[1], 'Longitude': lonlat[0], 'Altitude': 0 };
                }));
                break;
            case "LineString":
                _.merge(points, _.map(coordinates, function (coordinate) {
                    var lonlat = ol.proj.toLonLat(coordinate);
                    return { 'Latitude': lonlat[1], 'Longitude': lonlat[0], 'Altitude': 0 };
                }));
                break;
            case "MultiPolygon":
                _.merge(points, _.map(_.first(_.first(coordinates)), function (coordinate) {
                    var lonlat = ol.proj.toLonLat(coordinate);
                    return { 'Latitude': lonlat[1], 'Longitude': lonlat[0], 'Altitude': 0 };
                }));
                break;
            case "Polygon":
                _.merge(points, _.map(_.first(coordinates), function (coordinate) {
                    var lonlat = ol.proj.toLonLat(coordinate);
                    return { 'Latitude': lonlat[1], 'Longitude': lonlat[0], 'Altitude': 0 };
                }));
                break;
        }

        // Return points
        return points;
    }

    // Remove features 'dirty' from indexedDB
    function clearDirtyIndexedDB() {
        var deferred = $q.defer();
        dirty().then(function (geometries) {
            var promisses = [];
            _.each(geometries, function (geometry) {
                var defer = $q.defer();
                if (geometry.Handle == -1) {
                    // Remove geometry
                    _db.geometries.remove(geometry.GeometryId);
                    // Resolve
                    defer.resolve();
                } else {
                    // Restore points
                    _db.geometries.get(geometry.GeometryId).then(function (geom) {
                        // Remove dirty flag
                        geom.Dirty = false;
                        // Restore original points
                        geom.Points = geom.OriginalPoints;
                        // Original Spec
                        geom.SpecId = geom.OriginalSpecId;
                        // Restore object
                        _db.geometries.upsert(geom).then(function () {
                            // Resolve
                            defer.resolve();
                        });
                    })
                }
                promisses.push(defer.promise);
            });

            // Resolve all
            $q.all(promisses).then(function () {
                deferred.resolve();
            });
        });
        return deferred.promise;
    }

    // Load features from indexedDB
    function fromIndexedDB() {

        var deferred = $q.defer();

        // Retrieve geometries
        _db.geometries.all().then(function (geolines) {

            // Features
            var features = [];

            // Each geoline
            _.each(geolines, function (geoline) {

                // Create feature
                var feature = toFeature(geoline);

                // Set key
                feature.set('key', geoline.GeometryId);

                // Push to colleciton
                features.push(feature);
            });

            // Resolve
            deferred.resolve(features);
        });

        // Return promise
        return deferred.promise;
    }

    // Create feature from geoline
    function toFeature(geoline) {

        // Geometry
        var geometry = null;

        // Detect geometry type by CdoId
        var layer = _.first(_.filter($localStorage.map.layers, { 'CdoId': geoline.CdoId }));
        switch (layer.TypeName) {
            case "PointStyle":
                // Point
                geometry = new ol.geom.Point(ol.proj.fromLonLat([_.first(geoline.Points).Longitude, _.first(geoline.Points).Latitude]));
                break;
            case "LineStyle":
                // Line                
                geometry = new ol.geom.LineString(_.map(geoline.Points, function (point) { return ol.proj.fromLonLat([point.Longitude, point.Latitude]); }), 'XY');
                break;
            case "PolygonStyle":
                // Polygon
                geometry = new ol.geom.Polygon([_.map(geoline.Points, function (point) { return ol.proj.fromLonLat([point.Longitude, point.Latitude]); })], 'XY');
                break;
        }

        // ol.Feature
        var feature = new ol.Feature({ geometry: geometry, name: layer.name });

        // Add geoline
        feature.set('geoline', geoline);

        // Add CdoId
        feature.set('CdoId', geoline.CdoId);

        // Add layer
        feature.set('layer', layer);

        // Spec
        feature.set('SpecId', geoline.SpecId);

        // Handle
        feature.set('Handle', geoline.Handle);

        // Gps Track
        if (!_.isUndefined(geoline.GpsTrack))
            feature.set('GpsTrack', geoline.GpsTrack);        

        // Dirty
        feature.set('Dirty', geoline.Dirty);
        
        // DisplayFields
        if (!_.isUndefined(geoline.DisplayFields))
            feature.set('DisplayFields', geoline.DisplayFields);

        // Files
        if (!_.isUndefined(geoline.Files))
            feature.set('Files', geoline.Files);

        // LabelStyleValue
        if (!_.isUndefined(geoline.LabelStyleValue))
            feature.set('LabelStyleValue', geoline.LabelStyleValue);

        // Return feature  
        return feature;
    }
}]);