(function () {

    // Strict
    'use strict';

    // Factory
    angular.module('app').factory('style.label.factory', [function () {

        function getScale(_style, zoom) {
            var scale = 1;
            if (_style.IsScalable)
                scale = (1 / (Math.pow(2, 22 - zoom))) * 1.2
            return scale;
        }

        function getLabelSize(_style, text) {
            var canvas = document.createElement("canvas")
            var context = canvas.getContext("2d");
            context.font = 'normal ' + _style.FontSize + 'px ' + _style.FontFamily;
            var metrics = context.measureText(text);
            return { width: metrics.width + 6, height: _style.FontSize + 6 };
        }

        function getFontStyle(_style) {
            // FontStyle
            // Weight
            var weight = "normal";
            var style = "normal";
            var decoration = "none";
            switch (_style.FontStyle) {
                case 1:
                    weight = "bold";
                    break;
                case 2:
                    style = "italic";
                    break;
                case 3:
                    style = "italic";
                    weight = "bold";
                    break;
                case 4:
                    decoration = "underline";
                    break;
                case 5:
                    weight = "bold";
                    decoration = "underline";
                    break;
                case 6:
                    style = "italic";
                    decoration = "underline";
                    break;
                case 7:
                    weight = "bold";
                    decoration = "underline";
                    style = "italic";
                    break;
                case 8:
                    decoration = "line-through";
                    break;
                case 9:
                    weight = "bold";
                    decoration = "line-through";
                    break;
                case 10:
                    style = "italic";
                    decoration = "line-through";
                    break;
                case 11:
                    style = "italic";
                    weight = "bold";
                    decoration = "line-through";
                    break;
                case 12:
                    decoration = "underline line-through";
                    break;
                case 13:
                    weight = "bold";
                    decoration = "underline line-through";
                    break;
                case 14:
                    style = "italic";
                    decoration = "underline line-through";
                    break;
            }

            return { weight: weight, style: style, decoration: decoration };
        }

        function getAnchor(_style) {
            var anchorX = 0.5;
            var anchorY = 0.5;

            switch (_style.Alignment) {
                case 256:
                    anchorX = 1;
                    anchorY = 0;
                    break;
                case 512:
                    anchorX = 0.5;
                    anchorY = 0;
                    break;
                case 1024:
                    anchorX = 0;
                    anchorY = 0;
                    break;
                case 16:
                    anchorX = 1;
                    anchorY = 0.5;
                    break;
                case 64:
                    anchorX = 0;
                    anchorY = 0.5;
                    break;
                case 1:
                    anchorX = 1;
                    anchorY = 1;
                    break;
                case 2:
                    anchorX = 0.5;
                    anchorY = 1;
                    break;
                case 4:
                    anchorX = 0;
                    anchorY = 1;
                    break;
            }

            return { anchorX: anchorX, anchorY: anchorY };
        }

        return {
            getLabel: function (style, zoom, text) {
                
                // Scale
                var scale = getScale(style, zoom);

                // FontSize
                var fontSize = style.FontSize;

                // Scalable
                if (style.IsScalable && ((fontSize * scale) <= 3)) {                 
                      return null;
                }

                // FontStyle (weight, decoration, style)
                var fontStyle = getFontStyle(style);

                // Anchor
                var anchor = getAnchor(style);

                // Create canvas to get widht/height
                var size = getLabelSize(style, text);

                // Border
                var border = style.BorderVisible ? 2 : 0;

                // Background
                var background_opacity = style.BackColorEnabled ? style.BackColorOpacity : 0;

                // SVG
                var svg = '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="' + size.width + '" height="' + size.height + '"><g>' +
                    '<rect x="0" y="0" width="' + size.width + '" height="' + size.height + '" style="fill:' + convertHex(style.BackColorArgb, style.BackColorOpacity) + ';fill-opacity:' + background_opacity + ';stroke-width:' + border + ';stroke:' + convertHex(style.BorderColorArgb, style.BorderColorOpacity) + '" />' +
                    '<text x="' + size.width / 2 + '" y="' + size.height / 2 + '" fill="' + convertHex(style.FontColorArgb, style.FontColorOpacity) + '" font-family="' + style.FontFamily + '" text-anchor="middle" alignment-baseline="central" font-size="' + fontSize + '" font-weight="' + fontStyle.weight + '" font-style="' + fontStyle.style + '" text-decoration="' + fontStyle.decoration + '">' + text + '</text>' +
                    '</g></svg>';

                // Image size
                var imgSize = [size.width + Math.abs(style.OffsetX), size.height + Math.abs(style.OffsetY)];

                // Offset
                var offsetX = style.OffsetX > 0 ? -style.OffsetX : 0;
                var offsetY = style.OffsetY > 0 ? -style.OffsetY : 0;

                // Create image object.
                var img = new Image();
                img.src = 'data:image/svg+xml,' + escape(svg);

                return new ol.style.Style({
                    zIndex: 9999,
                    image: new ol.style.Icon({
                        "img": img,
                        "imgSize": imgSize,
                        "anchor": [anchor.anchorX, anchor.anchorY],
                        "offset": [offsetX, offsetY],
                        "scale": scale
                    }),
                    geometry: function (feature) {
                        if (feature.getGeometry().getType() === 'Point') {
                            return feature.getGeometry();
                        }
                        if (feature.getGeometry().getType() == 'LineString') {
                            return new ol.geom.Point(ol.extent.getCenter(feature.getGeometry().getExtent()));
                        }
                        if (feature.getGeometry().getType() === 'Polygon') {
                            return feature.getGeometry().getInteriorPoint();
                        }                        
                    }
                });
            }
        }
    }]);
}());