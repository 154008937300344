(function () {

    // Strict
    'use strict';

    // Controller
    angular.module('app').controller('nav.controller', ['$rootScope', '$scope', '$location', '$filter', '$uibModal', '$confirm', '$localStorage', '$q', 'toastr', 'auth.factory', 'map.factory', 'task.factory', 'geometry.factory', 'log.factory', 'modal.service', 'config', 'cache.service', 'db.factory', 'sync.service', function ($rootScope, $scope, $location, $filter, $uibModal, $confirm, $localStorage, $q, toastr, _auth, _map, _task, _geometry, _log, _modal, _config, _cache, _db, _sync) {

        // Variabeles
        var dialog = null;

        // Config
        $scope.config = _config.version;

        // Check if any tasks or geometry is dirty
        $scope.dirty = 0;
        $scope.checkDirty = function () {            
            $q.all([_task.dirty(), _geometry.dirty()]).then(function (result) {
                $scope.dirty = result[0].length + result[1].length;
            });
        };
        $scope.checkDirty();
        $rootScope.$on('application:tasks:dirty', function () { $scope.checkDirty(); });

        // Sync
        $scope.sync = function () {
            // Show loading
            $rootScope.$broadcast('loading:show');

            // Sending data
            dialog = _modal.alert({ text: $filter('translate')('labels.sendingdatapleasewait') });

            // Sync task and geometry
            _sync.all().then(function () {                
                // Hide loading
                $rootScope.$broadcast('loading:hide');
                // Done sync
                $rootScope.$broadcast('application:data:synced');
                // Close dialog
                dialog.dismiss('close');
                dialog = null;
            });
        };

        // Home
        $scope.home = function () {
            $location.path('./');
        };

        // Logout
        $scope.logout = function () {
            if ($rootScope.applicationOnline) {
                // Check if there is any data to be send.
                _sync.syncAllWithConfirm().then(function (result) {                    
                    if (!result.error) {
                        // Clear task/geometry
                        $q.all([_db.tasks.clear(), _db.geometries.clear()]).then(function () {
                            // Update dirty
                            $rootScope.$broadcast('application:tasks:dirty');
                            // Logout
                            _auth.logout().then(function () {
                                // Redirect to login
                                $location.path('/login');
                            });
                        });
                    }
                });
            } else {
                _sync.dismissAllWithConfirm().then(function () {
                    // Update dirty
                    $rootScope.$broadcast('application:tasks:dirty');
                    // Logout
                    _auth.logout().then(function () {
                        // Redirect to login
                        $location.path('/login');
                    });
                });
            }
        };

        // Reset
        $scope.reset = function () {

            // If offline show message
            var message = $rootScope.applicationOnline ? $filter('translate')('labels.refreshcachemessage') : $filter('translate')('labels.refreshcacheoffline');
            $confirm({ text: message, title: $filter('translate')('labels.refreshcachetitle'), ok: $filter('translate')('labels.ok'), cancel: $filter('translate')('labels.cancel') }).then(function () {
                // Loading
                $rootScope.$broadcast("loading:show");

                // Dialog with processing message
                var dialog = _modal.alert({ text: $filter('translate')('labels.refreshcachepleasewait') });

                // Requests
                var requests = [];

                // Reload cache
                if ($rootScope.applicationOnline)
                    requests.push(_cache.reset());

                // Clear geometry
                requests.push(_db.geometries.clear());

                // Clear task
                requests.push(_db.tasks.clear());

                $q.all(requests).then(function () {

                    // Load settings
                    $localStorage.settings = angular.copy(customerSettings);
                    $rootScope.settings = _.clone($localStorage.settings);
                    
                    // Reload baselayer
                    _map.baselayer.load();

                    // Reload geomtries
                    $rootScope.$broadcast('application:map:reload');
                    // Reload tasks
                    $rootScope.$broadcast('application:tasks:reload');
                    // Close dialog
                    dialog.dismiss('close');
                    // Loading
                    $rootScope.$broadcast("loading:hide");
                    // Update
                    $rootScope.$broadcast('application:tasks:dirty');
                });
            });
        };

        // Log
        $scope.log = function () {
            $uibModal.open({
                templateUrl: 'app/settings/log/log.view.html',
                controller: 'log.controller',
                size: 'lg',
            });
        }

        // Settings
        $scope.settings = function () {
            $uibModal.open({
                templateUrl: 'app/settings/settings.view.html',
                controller: 'settings.controller',
                size: 'lg',
            }).result.then(function () {
                // Reload baselayer
                _map.baselayer.load();
            });
        }

        // Visible Object Types
        $scope.vot = function () {
            $uibModal.open({
                templateUrl: 'app/settings/vot/vot.view.html',
                controller: 'vot.controller',
                size: 'lg',
            });
        }

        // Online / Offline
        $scope.online = function () {
            if ($rootScope.applicationOnline) {
                $localStorage.applicationOnline = false;
                $rootScope.applicationOnline = false;
            } else {
                if (!$rootScope.online) {
                    // Alert
                    _modal.alert({ text: $filter('translate')('labels.noconnection_message'), ok: $filter('translate')('labels.ok') });
                } else {
                    $localStorage.applicationOnline = true;
                    $rootScope.applicationOnline = true;
                }
            }
        }

        // isAuthenticated
        $scope.isAuthenticated = function () {
            return _auth.isAuthenticated();
        };

    }]);

}());