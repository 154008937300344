(function () {

    // Strict
    'use strict';

    // Factory
    angular.module('app').factory('api.factory', ['$rootScope', '$q', '$http', '$localStorage', '$sessionStorage', '$resource', 'db.factory', 'log.factory', function ($rootScope, $q, $http, $localStorage, $sessionStorage, $resource, _db, _log) {

        return {
            getMapsettingsFile: function () {

                // Defer
                var deferred = $q.defer();

                // GetMapSettingsFileJson
                $http.post($rootScope.settings.webserviceUrl + 'GetMapSettingsFileJson', {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(function (result) {

                    // Map layers
                    result.data.Layers = _.map(result.data.ISXmlLayers, function (layer) {
                        return {
                            Name: layer.Name,
                            CdoId: layer.CdoId,
                            TypeName: layer.Style.TypeName,
                            Shape: layer.Style.Shape,
                            Size: layer.Style.Size,
                            IsEditable: layer.IsEditable,
                            IsScalable: layer.IsScalable,
                            IsSelectable: layer.IsSelectable,
                            IsVisible: layer.IsVisible,
                            MinZoomLevel: layer.MinZoomLevel,
                            MaxZoomLevel: layer.MaxZoomLevel,
                            LineWidth: layer.Style.LineWidth,
                            LineColor: argbToRGB(layer.Style.LineColorArgb),
                            LineOpacity: argbToOpacity(layer.Style.LineColorArgb),
                            FillForeColor: argbToRGB(layer.Style.FillForeColorArgb),
                            FillForeOpacity: argbToOpacity(layer.Style.FillForeColorArgb),
                            FillBackColor: argbToRGB(layer.Style.FillBackColorArgb),
                            FillBackOpacity: argbToOpacity(layer.Style.FillBackColorArgb),
                            DisplayFields: layer.DisplayFields,
                            ShowLabels: layer.ShowLabels,
                            LabelStyles: _.each(layer.LabelStyles, function (labelStyle) {
                                labelStyle.BackColorArgb = argbToRGB(labelStyle.BackColorArgb);
                                labelStyle.BorderColorArgb = argbToRGB(labelStyle.BorderColorArgb);
                                labelStyle.DropShadowColorArgb = argbToRGB(labelStyle.DropShadowColorArgb);
                                labelStyle.FontColorArgb = argbToRGB(labelStyle.FontColorArgb);
                            }),
                            ExtendedAttributes: layer.ExtendedAttributes
                        };
                    });

                    // Resolve
                    deferred.resolve(result.data);
                }, function (error) {
                    // Error
                    deferred.reject(error);
                });

                // Return promise
                return deferred.promise;
            },            
            getGeometries: function (extent, cdoTypes) {

                // Defer
                var deferredAbort = $q.defer();

                // Abort request
                var abort = function (reason) {
                    canceler.resolve(reason);
                };

                // Request
                var request = $http({
                    url: $rootScope.settings.webserviceUrl + 'GetObjectsByCoordinate',
                    method: 'POST',
                    data: $.param({
                        topLeftLat: extent[3],
                        topLeftLon: extent[0],
                        bottomRightLat: extent[1],
                        bottomRightLon: extent[2],
                        cdoTypes: cdoTypes,
                        spatialReferenceId: $rootScope.settings.defaultReferenceId
                    }),
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    timeout: deferredAbort.promise,
                    transformResponse: function (data, header, status) {
                        var geometries = [];

                        if (status == 500) {
                            _log.error('api.factory', 'getGeometries', { data: angular.fromJson(data), status: status });
                        }

                        if (status == 200) {
                            geometries = angular.fromJson(data);
                            _.each(geometries, function (geometry) {
                                // Set dirty
                                geometry.Dirty = false;
                                // Create backup 'Points'.
                                if (!_.isUndefined(geometry.Points) && !_.isNull(geometry.Points))
                                    geometry.OriginalPoints = geometry.Points;
                                // Create backup SpecId
                                if (!_.isUndefined(geometry.SpecId) && !_.isNull(geometry.SpecId))
                                    geometry.OriginalSpecId = geometry.SpecId;
                            });
                        }
                        return geometries;
                    }
                });

                // Promise
                var promise = request.then(function (response) {
                    return (response);
                }, function (error) {
                    return $q.reject(error);
                });

                // Abort
                promise.abort = function () {
                    deferredAbort.resolve();
                };

                // Cleanup
                promise.finally(function () {
                    promise.abort = angular.noop;
                    deferredAbort = request = promise = null;
                });

                // Return promise
                return (promise);
            },
            commitGeometries: function (geometries) {

                // Defer
                var deferred = $q.defer();

                // Promises
                var promises = [];


                // Build chain
                var chain = geometries.map(function (geometry) {
                    var d = $q.defer();

                    _.each(geometry.DisplayFields, function (displayField) {
                        if (displayField.DataType == 'double' && displayField.Value != null && displayField.Value != '') {
                            displayField.Value = displayField.Value.replace(',', '.');
                        }
                        if ((displayField.DataType == 'date' || displayField.DataType == 'datetime') && !_.isNull(displayField.Value))
                            displayField.Value = new Date(displayField.Value).toISOString();
                    });

                    $http.post($rootScope.settings.webserviceUrl + 'UpdateGeometry', {
                        handle: geometry.Handle,
                        cdoId: geometry.CdoId,
                        points: JSON.stringify(geometry.Points),
                        projectHandle: geometry.ProjectHandle,
                        spatialReferenceId: $rootScope.settings.defaultReferenceId,
                        specId: geometry.SpecId,
                        displayFields: geometry.DisplayFields,
                        files: geometry.Files,
                    }, {
                            headers: { 'Content-Type': 'application/json' }
                        }).then(function (result) {
                            // Add handle to geometry
                            geometry.Handle = result.data.Handle;
                            // Not dirty
                            geometry.Dirty = false;
                            // Set originalPoints to the current points to restore to the right position
                            geometry.OriginalPoints = geometry.Points;
                            // Update database
                            _db.geometries.upsert(geometry).then(function () {
                                d.resolve({ error: false });
                            });
                        }, function (error) {
                            _log.error('api', 'api.commitGeometries', error);
                            d.resolve({ error: true });
                        });
                    return d.promise;
                });

                // Run chain
                $q.all(chain).then(function (results) {
                    if (!_.isUndefined(results)) {
                        deferred.resolve({ error: _.filter(results, { error: true }).length > 0 });
                    } else {
                        deferred.resolve({ error: false })
                    }
                });

                // Return promise
                return deferred.promise;
            },
            getSpecs: function () {
                return $http.get($rootScope.settings.webserviceUrl + "GetSpecs", {});
            },
            getTasks: function (from, to) {

                // Defer
                var deferredAbort = $q.defer();

                // Abort request
                var abort = function (reason) {
                    canceler.resolve(reason);
                };

                // Request
                var request = $http({
                    url: $rootScope.settings.webserviceUrl + 'GetTasks',
                    method: 'POST',
                    data: $.param({
                        from: from,
                        to: to
                    }),
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    timeout: deferredAbort.promise,
                    transformResponse: function (data, header, status) {
                        var tasks = [];

                        if (status == 500) {
                            _log.error('api.factory', 'getTasks', { data: angular.fromJson(data), status: status });
                        }

                        if (status == 200) {
                            tasks = angular.fromJson(data);
                            _.each(tasks, function (task) {

                                // Set dirty
                                task.Dirty = false;

                                // Afgehandeld
                                task.Finished = task.Status == 'Finished' || task.Status == 'Processed';

                                // Signature
                                if (!_.isNull(task.Signature) && !_.isEmpty(task.Signature))
                                    task.Signature = "data:image/png;base64," + task.Signature;

                                // Photo
                                if (!_.isNull(task.Photo) && !_.isEmpty(task.Photo))
                                    task.Photo = "data:image/png;base64," + task.Photo;

                                // Set ActualStart / ActualEnd
                                if (!_.isNull(task.ActualStartTime))
                                    task.ActualStartTime = new Date(task.ActualStartTime).compensateTimeZone();
                                if (!_.isNull(task.ActualEndTime))
                                    task.ActualEndTime = new Date(task.ActualEndTime).compensateTimeZone();

                                // Format columns
                                _.each(task.Activity.Columns, function (column) {
                                    if ((column.Type == 'date' || column.Type == 'datetime') && !_.isEmpty(column.Value))
                                        column.Value = new Date(column.Value);
                                    if (column.Type == 'double' && column.Value != null) {
                                        column.Value = column.Value.toString().replace(".", $rootScope.settings.decimalSeperator);
                                    }
                                });

                                // Remote SubTasks
                                delete task.SubTasks;
                            });
                        }
                        return tasks;
                    }
                });

                // Promise
                var promise = request.then(function (response) {
                    return (response);
                }, function (error) {
                    return $q.reject(error);
                });

                // Abort
                promise.abort = function () {
                    deferredAbort.resolve();
                };

                // Cleanup
                promise.finally(function () {
                    promise.abort = angular.noop;
                    deferredAbort = request = promise = null;
                });

                // Return promise
                return (promise);
            },
            commitTasksAsync: function (tasks) {
                // Defer
                var deferred = $q.defer();

                // Promises
                var promises = [];

                // Build chain
                var chain = tasks.map(function (task) {
                    var d = $q.defer();

                    // Format column values
                    _.each(task.Activity.Columns, function (column) {
                        if (column.Type == 'double' && column.Value != null && column.Value != '')
                            column.Value = column.Value.replace(',', '.');
                        if ((column.Type == 'date' || column.Type == 'datetime') && !_.isEmpty(column.Value))
                            column.Value = new Date(column.Value).toISOString();
                    });

                    $http.post($rootScope.settings.webserviceUrl + 'SaveTask', {
                        task: task,
                        parenttaskid: task.ParentTaskId,
                        spatialReferenceId: $rootScope.settings.defaultReferenceId
                    }, {
                            headers: { 'Content-Type': 'application/json' }
                        }).then(function (result) {

                            // Set taskId
                            task.TaskId = result.data.TaskId;

                            // Set ObjectHandle
                            task.ObjectHandle = result.data.ObjectHandle;

                            // Set dirty
                            task.Dirty = false;

                            // Find parent
                            if (task.ParentId > 0 && task.ParentTaskId == 0) {
                                _db.tasks.get(task.ParentId).then(function (parent) {
                                    task.ParentTaskId = parent.TaskId;

                                    // Update indexedDB
                                    _db.tasks.upsert(task).then(function () {
                                        // Resolve
                                        d.resolve({ error: false });
                                    });

                                })
                            } else {
                                // Update indexedDB
                                _db.tasks.upsert(task).then(function () {
                                    // Resolve
                                    d.resolve({ error: false });
                                });
                            }
                        }, function (error) {
                            _log.error('api', 'api.commitTaskAsync', error);
                            d.resolve({ error: true });
                        });
                    return d.promise;
                });

                // Run chain
                $q.all(chain).then(function (results) {
                    if (!_.isUndefined(results)) {
                        deferred.resolve({ error: _.filter(results, { error: true }).length > 0 });
                    } else {
                        deferred.resolve({ error: false })
                    }
                });

                // Return promise
                return deferred.promise;
            },
            commitTasksSync: function (tasks) {

                // Defer
                var deferred = $q.defer();

                // State
                var state = { error: false };

                // Send task
                var send = function (task) {
                    var d = $q.defer();
                    var post = function () {

                        // Format column values
                        _.each(task.Activity.Columns, function (column) {
                            if (column.Type == 'double' && column.Value != null && column.Value != '')
                                column.Value = column.Value.replace(',', '.');
                            if ((column.Type == 'date' || column.Type == 'datetime') && !_.isEmpty(column.Value))
                                column.Value = new Date(column.Value).toISOString();
                        });

                        $http.post($rootScope.settings.webserviceUrl + 'SaveTask', {
                            task: task,
                            parenttaskid: task.ParentTaskId,
                            spatialReferenceId: $rootScope.settings.defaultReferenceId
                        }, {
                                headers: { 'Content-Type': 'application/json' }
                            }).then(function (result) {

                                // Set taskId
                                task.TaskId = result.data.TaskId;

                                // Set ObjectHandle
                                task.ObjectHandle = result.data.ObjectHandle;

                                // Set dirty
                                task.Dirty = false;

                                // Update indexedDB
                                _db.tasks.upsert(task).then(function () {
                                    d.resolve();
                                });
                            }, function (error) {
                                _log.error('api', 'api.commitTaskSync', error);
                                d.reject();
                            });
                    }

                    // Find parent
                    if (task.ParentId > 0 && task.ParentTaskId == 0) {
                        _db.tasks.get(task.ParentId).then(function (parent) {
                            task.ParentTaskId = parent.TaskId;
                            post(task);
                        })
                    } else {
                        post(task);
                    }

                    return d.promise;
                };

                // Run chain sequential
                var results = [];
                var chain = tasks.reduce(function (promise, task) {
                    return promise.then(function () {
                        return send(task);
                    }, function () {
                        state.error = true;
                    });
                }, $q.resolve());

                // Wait for all to finish
                chain.then(function () {
                    deferred.resolve(state);
                });

                // Return promise
                return deferred.promise;
            },
            getActivities: function () {
                return $http.post($rootScope.settings.webserviceUrl + 'GetActivities', {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                });
            },
            getInfoColumnNames: function (cdoId) {

                var deferred = $q.defer();
                // Set object spec
                $http.post($rootScope.settings.webserviceUrl + 'GetInfoColumnNames', $.param({
                    cdoId: cdoId
                }), {
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                    }).then(function (result) {
                        // Resolve
                        deferred.resolve(result.data);
                    }, function (error) {
                        // Error
                        deferred.reject(error);
                    });
                // Return promise
                return deferred.promise;
            },
            getObjectInfoValues: function (handle, columns) {

                var deferred = $q.defer();
                // Set object spec
                $http.post($rootScope.settings.webserviceUrl + 'GetObjectInfoValues', $.param({
                    handle: handle,
                    columns: columns.join(',')
                }), {
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                        transformResponse: function (data, header, status) {
                            var values = [];
                            if (status == 200) {
                                var displayFields = angular.fromJson(data);
                                _.each(displayFields, function (displayField) {
                                    var value = displayField.Value;
                                    if (displayField.DataType == "double" && !_.isEmpty(value))
                                        value = value.toString().replace(".", $rootScope.settings.decimalSeperator);
                                    if ((displayField.DataType == "date" || displayField.DataType == "datetime") && !_.isEmpty(value))
                                        value = new Date(value);
                                    values.push(value);
                                });
                            }
                            return values;
                        }
                    }).then(function (result) {
                        // Resolve
                        deferred.resolve(result.data);
                    }, function (error) {
                        // Error
                        deferred.reject(error);
                    });
                // Return promise
                return deferred.promise;
            },
            getObjectFiles: function (handle) {
                var deferred = $q.defer();
                // Set object spec
                $http.post($rootScope.settings.webserviceUrl + 'GetObjectFiles', $.param({
                    handle: handle
                }), {
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                        transformResponse: function (data, header, status) {
                            var files = [];
                            if (status == 200) {
                                files = angular.fromJson(data);
                            }
                            return files;
                        }
                    }).then(function (result) {
                        // Resolve
                        deferred.resolve(result.data);
                    }, function (error) {
                        // Error
                        deferred.reject(error);
                    });
                // Return promise
                return deferred.promise;
            },
            getProjects: function (project) {
                var deferred = $q.defer();
                $http.post($rootScope.settings.webserviceUrl + 'GetProjects', {
                    project: project
                }, {
                        headers: { 'Content-Type': 'application/json' }
                    }).then(function (result) {
                        deferred.resolve(result.data);
                    });
                // Return promise
                return deferred.promise;
            },
            getAllowedCdoTypesForRoot: function () {
                return $http.get($rootScope.settings.webserviceUrl + "GetAllowedCdoTypesForRoot", {});
            },
            getAllowedCdoTypesForProject: function (handle) {
                
                var deferred = $q.defer();
                // Set object spec
                $http.post($rootScope.settings.webserviceUrl + 'GetAllowedCdoTypesForProject', $.param({
                    projectHandle: handle
                }), {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    transformResponse: function (data, header, status) {
                        var json = [];
                        if (status == 200) {
                            json = angular.fromJson(data);
                        }
                        return json;
                    }
                }).then(function (result) {
                    // Resolve
                    deferred.resolve(result.data);
                }, function (error) {
                    // Error
                    deferred.reject(error);
                });
                // Return promise
                return deferred.promise;
            }
        };
    }]);

}());