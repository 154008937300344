(function () {
    'use strict';

    angular.module('app').factory('PagePanel', ['$rootScope', function ($rootScope) {

        // Constructor
        function PagePanel(options) {

            // Extend options
            options = angular.extend({ id: -1, parent: null, title: 'Title', subtitle: 'Subtitle', frame: 'unknow' }, options);
            
            // Variables
            this.id = options.id;
            this.title = options.title;
            this.subtitle = options.subtitle;
            this.frame = options.frame;
            this.parent = options.parent;
        }

        // Return model
        return PagePanel;
    }]);

})();