(function () {

    // Strict
    'use strict';

    // Factory
    angular.module('app').factory('log.factory', ['db.factory', function (_db) {
        return {
            error: function (func, call, msg) {
                var errorMessage = "";

                // Error
                if (!_.isUndefined(msg.status)) {
                    if (msg.status == 500) {
                        var exception = msg.data;
                        if (exception && exception.ExceptionMessage != null) {
                            errorMessage += exception.ExceptionMessage;
                            if (exception.InnerException != null && exception.InnerException.ExceptionMessage != null) {
                                errorMessage += ": " + exception.InnerException.ExceptionMessage;
                            }
                        }
                    } else if (msg.status == -1) {
                        // Abort
                        errorMessage = "Request abort";
                    } else {
                        // Generic error
                        errorMessage = "Generic Error"
                    }
                } else {
                    errorMessage = msg;
                }

                // Write to localStorage
                _db.logs.add({ DateTime: new Date(), Function: func, Call: call, ErrorMessage: errorMessage });
            }
        };
    }]);

}());