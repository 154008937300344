(function () {
    'use strict';

    angular.module('app').factory('compress.service', ['$q', function ($q) {

        var options = {
            resizeQuality: 0.8,
            resizeMaxHeight: 768,
            resizeMaxWidth: 1024
        }

        var getResizeArea = function () {
            var resizeAreaId = 'fileupload-resize-area';

            var resizeArea = document.getElementById(resizeAreaId);

            if (!resizeArea) {
                resizeArea = document.createElement('canvas');
                resizeArea.id = resizeAreaId;
                resizeArea.style.visibility = 'hidden';
                document.body.appendChild(resizeArea);
            }

            return resizeArea;
        };

        var jicCompress = function (sourceImgObj, resizeType) {
            var quality = options.resizeQuality * 100;

            if (resizeType == "jpg")
                resizeType = "jpeg";

            var mimeType = 'image/' + resizeType;

            var maxHeight = options.resizeMaxHeight;
            var maxWidth = options.resizeMaxWidth;

            var height = sourceImgObj.height;
            var width = sourceImgObj.width;

            // calculate the width and height, constraining the proportions
            if (width > height) {
                if (width > maxWidth) {
                    height = Math.round(height *= maxWidth / width);
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width = Math.round(width *= maxHeight / height);
                    height = maxHeight;
                }
            }
            
            var cvs = document.createElement('canvas');
            cvs.width = width; //sourceImgObj.naturalWidth;
            cvs.height = height; //sourceImgObj.naturalHeight;
            var ctx = cvs.getContext('2d').drawImage(sourceImgObj, 0, 0, width, height);
            var newImageData = cvs.toDataURL(mimeType, quality / 100);
            var resultImageObj = new Image();
            resultImageObj.src = newImageData;
            return resultImageObj.src;

        };

        var createImage = function (url, callback) {
            var image = new Image();
            image.onload = function () {
                callback(image);
            };
            image.src = url;
        };

        return {
            compress: function (url, imageType) {
                var deferred = $q.defer();
                createImage(url, function (image) {
                    var dataURLcompressed = jicCompress(image, imageType);
                    deferred.resolve(dataURLcompressed);
                });
                return deferred.promise;
            }
        };

    }]);
})();