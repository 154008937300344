(function () {

    // Strict
    'use strict';

    angular.module('app').factory('oauthInterceptor', ['$rootScope', '$q', '$location', '$localStorage', function ($rootScope, $q, $location, $localStorage) {
        return {
            'request': function (config) {
                config.headers = config.headers || {};
                // Skip google map request, proxy requests, geoserver requests
                if (config.url.indexOf($rootScope.settings.webserviceUrl + 'proxy') < 0 && config.url.indexOf('//maps.google.com/maps/api/geocode/') < 0 && $localStorage.token && $localStorage.token.access_token) {
                    config.headers.Authorization = 'Bearer ' + $localStorage.token.access_token;
                }
                return config;
            },
            'responseError': function (response) {
                if (response.config.url.indexOf($rootScope.settings.webserviceUrl + 'proxy') < 0 && response.config.url.indexOf('//maps.google.com/maps/api/geocode/') < 0) {
                    if (response.status === 401 || response.status === 403) {
                        // Redirect
                        $location.path('/logout');
                    }
                }                
                return $q.reject(response);
            }
        };        
    }]);

}());