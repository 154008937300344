(function () {

    'use strict';

    // Directive prevent click propagation
    angular.module('app').directive('clickOnce', function () {
        return {
            link: function (scope, elem) {
                elem.on('click', function (e) {
                    e.stopPropagation();                    
                });
            }
        };
    });

}());