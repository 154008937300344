

(function () {

    // Strict
    'use strict';

    // Factory
    angular.module('app').factory('auth.factory', ['$rootScope', '$q', '$http', '$httpParamSerializer', '$localStorage', '$sessionStorage', '$location', function ($rootScope, $q, $http, $httpParamSerializer, $localStorage, $sessionStorage, $location) {
        return {
            login: function (username, password) {

                // Defer
                var deferred = $q.defer();

                // userExists
                $http.post($rootScope.settings.webserviceUrl + 'oauth/request_token', $httpParamSerializer({ username: username, password: password, grant_type: 'password' })).then(function (response) {
                    
                    // Login failed
                    if (!response.data) {
                        deferred.reject("Incorrect credentials");
                        return;
                    }

                    // Set token
                    $localStorage.token = response.data;

                    // Set localstorage
                    $localStorage.lastUserInfo = {
                        username: response.data.userName
                    };

                    // Resolve
                    deferred.resolve();

                }, function (error) {                    
                    // Error
                    deferred.reject(error);
                });

                // Return promise
                return deferred.promise;
            },
            logout: function () {

                // Defer
                var deferred = $q.defer();
                
                // Clear token
                delete $localStorage.token;

                // Resolve
                deferred.resolve();

                // Return promise
                return deferred.promise;
            },
            isTokenExpired: function() {
                if (this.isAuthenticated()) {
                    // Is token expired?
                    var expires = Date.parse($localStorage.token['.expires']);
                    if (expires > Date.now())
                        return false;
                }
                return true;
            },
            isAuthenticated: function () {
               return $localStorage.token && $localStorage.token.access_token;
            }
        };
    }]);

}());
