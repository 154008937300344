(function () {
    'use strict';

    angular.module('app').directive('loading', ['$rootScope', '$filter', function ($rootScope, $filter) {
        return {
            restrict: 'E',
            replace: true,
            template: '<div class="loading"><div class="spinner"></div></div>',
            link: function ($scope, element, attrs) {
                $scope.$on("loading:show", function () {
                    return element.show();
                });
                return $scope.$on("loading:hide", function () {
                    return element.hide();
                });
            }
        }
    }]);

})();