(function () {
    'use strict';

    angular.module('app').directive('pagePanel', ['$rootScope', '$document', '$filter', '$window', '$timeout', function ($rootScope, $document, $filter, $window, $timeout) {
        return {
            restrict: 'A',
            replace: false,
            transclude: true,
            templateUrl: './app/page-panel/page-panel.directive.html',
            scope: {
                id: '@pagePanelId',
                parent: '=pagePanelParent',
                title: '@pagePanelTitle',
                subtitle: '@pagePanelSubtitle',
                onclose: '&?pagePanelOnclose',
                onresize: '&?pagePanelOnresize',
                frame: '@pagePanelFrame'
            },
            controller: ['$rootScope', '$scope', '$element', function ($rootsScope, $scope, $element) {

                // Visible
                $scope.visible = true;
                $scope.fullscreen = false;
                $scope.child = null;
                
                // Map always on top
                $scope.mapAlwaysOnTop = function () {
                    // Inverse mapAlwaysOnTop
                    $rootScope.mapAlwaysOnTop = !$rootScope.mapAlwaysOnTop;
                    // If true then scroll to last page-panel
                    if ($rootScope.mapAlwaysOnTop && $scope.last()) {
                        angular.element($window).triggerHandler('resize');                
                    }
                }

                // Close child
                $scope.closeChild = function () {
                    // Close child panel
                    if (!_.isNull($scope.child)) {                        
                        $rootScope.$broadcast('application:panel:close', { id: $scope.child.id });
                        $scope.child = null;
                    }
                }

                // Close panel
                $scope.close = function ($event) {                                        
                    // Custom close event
                    if (_.isFunction($scope.onclose)) {
                        // No more fullscreen
                        $scope.fullscreen = false;
                        // Execute event
                        $scope.onclose({ id: $scope.id });
                        // Quit method
                        return;
                    }
                    // Close child
                    $scope.closeChild();
                    // Destroy scope, remove element from DOM
                    destroy();
                    // Remove panel from collection            
                    $rootScope.panels = _.reject($rootScope.panels, function (panel) {
                        return panel.id == $scope.id;
                    });                    
                    // If this panel is child close it.
                    $rootScope.$broadcast('application:panel:child:close', { id: $scope.id });
                    // Event event is defined call
                    if ($scope.onclose)
                        $scope.onclose({ id: $scope.id });
                }

                // Add new panel
                $scope.add = function (panel) {
                    // Close child
                    $scope.closeChild();                    
                    // Add to children
                    $scope.child = panel;
                    // Add panel to collection
                    $rootScope.panels.push(panel);
                }

                // Toggle Fullscreen
                $scope.resize = function () {
                    // Toggle 
                    $scope.fullscreen = !$scope.fullscreen;
                    // Call resize event if exists
                    if ($scope.onresize)
                        $scope.onresize({ fullscreen: $scope.fullscreen });
                }

                // Scroll to panel
                $scope.scrollToPanel = function () {
                    // Scroll parent to this element
                    var offset = _.first($element).offsetLeft + ($rootScope.panels.length * 1000);
                    $(_.first($element).parentElement).scrollLeftAnimated(offset);
                }

                // Watch fullscreen
                $scope.$watch('fullscreen', function (newValue, oldValue) {
                    if ($scope.fullscreen) {
                        // Add fullscreen class
                        $($element).addClass('page-panel-fullscreen');                        
                    } else {
                        // Remove fullscreen class
                        $($element).removeClass('page-panel-fullscreen');
                    }
                    // Scroll to panel
                    $scope.scrollToPanel();
                });
              
                // Last
                $scope.last = function () {
                    var last = _.last($rootScope.panels);
                    if (!_.isNull(last) && !_.isUndefined(last)) {
                        var _l = last.id == $scope.id;
                        // If not last panel and is fullscreen then exit fullscreen.
                        if (!_l && $scope.fullscreen)
                            $scope.fullscreen = false;
                        return _l
                    } else
                        return true;
                }

                // Destroy scope and remove element from DOM
                function destroy() {
                    $scope.$destroy();
                    $element.remove();
                }
            }],
            link: function ($scope, element, attrs) {

                // Scroll to panel
                $scope.scrollToPanel();

                // Close panel by event
                $rootScope.$on('application:panel:close', function ($event, args) {                    
                    if (args.id == $scope.id)
                        $scope.close();                    
                });

                // On child close remove child
                $rootScope.$on('application:panel:child:close', function ($event, args) {                    
                    if (!_.isNull($scope.child) && $scope.child.id == args.id) {
                        $scope.child = null;
                    }
                });

                // Close fullscreen
                $rootScope.$on('application:sidebar:map', function ($event, args) {
                    if ($scope.id == -1)
                        $scope.fullscreen = false;
                });

                // If window resize
                angular.element($window).bind('resize', function () {
                    if ($rootScope.mapAlwaysOnTop && $scope.last()) {
                        // Scroll to panel
                        $scope.scrollToPanel();                        
                    }
                });
            }
        }
    }]);

})();