(function () {

    // Strict
    'use strict';

    // Controller
    angular.module('app').controller('login.controller', ['$rootScope', '$scope', '$filter', '$localStorage', '$location', 'toastr', 'auth.factory', 'log.factory', 'cache.service', 'sync.service', function ($rootScope, $scope, $filter, $localStorage, $location, toastr, _auth, _log, _cache, _sync) {

        // Page
        $scope.page = {
            loading: false
        };

        // Model
        $scope.username = $localStorage.lastUserInfo && $localStorage.lastUserInfo.username || $scope.settings.loginSettings.defaultUsername;
        $scope.password = '';

        // Login
        $scope.login = function () {
            // Loading
            $rootScope.$broadcast("loading:show");
            // Login
            _auth.login($scope.username, $scope.password).then(function (token) {
                // Reload
                _cache.load().then(function () {
                    // Loading
                    $rootScope.$broadcast("loading:hide");
                    // Redirect
                    $location.path('/home');
                    // Check if there is any data to be send.
                    _sync.syncAllWithConfirm('labels.syncfounddirty');
                });
            }, function (error) {
                // Write to log
                _log.error("login", "auth.login", error);
                // End loading
                $rootScope.$broadcast("loading:hide");
                // Show modal
                toastr.error($filter('translate')('messages.incorrectcredentialstitle'));
            });
        };

    }]);
}());