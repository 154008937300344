(function () {

    // Strict
    'use strict';

    // Controller
    angular.module('app').controller('vot.controller', ['$rootScope', '$scope', '$location', '$localStorage', '$uibModalInstance', function ($rootScope, $scope, $location, $localStorage, $uibModalInstance) {
        
        // Settings
        $scope.cdotypes = angular.copy($localStorage.cdotypes);

        $scope.gpsTrackLayer = $localStorage.settings.gpsTrackLayer;
        console.log($scope.gpsTrackLayer);  
        // Select
        // options.all = true/false
        $scope.select = function (options) {
            _.each($scope.cdotypes, function (cdotype) {
                cdotype.Visible = options.all;
            });
        };

        // Save
        $scope.save = function () {

            // Save cdotypes into localStorage and rootScope
            $localStorage.cdotypes = $scope.cdotypes;
            
            // Reload WMS layer
            $rootScope.$broadcast('application:map:wms:visible');

            // Redirect to default path
            $uibModalInstance.close();
        };

        // Cancel
        $scope.cancel = function () {

            // Restore cdotypes
            $scope.cdotypes = angular.copy($localStorage.cdotypes);
            
            // Redirect to default path
            $uibModalInstance.dismiss('cancel');
        };

    }]);

}());